import React, { useEffect, useRef, useState } from 'react';
import './StreamComponent.css';
import './OvVideo.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faWifi } from '@fortawesome/free-solid-svg-icons';
import CircularProgress from '@material-ui/core/CircularProgress';
import classNames from 'classnames';

export default function OvVideoComponent(props) {
  const videoRef = useRef();
  const [showPlay, setShowPlay] = useState(false);
  const timerShowPLay = useRef(undefined);
  const timerTryPlay = useRef(undefined);

  useEffect(() => {
    if (props.user.isRemote() && videoRef.current.srcObject === null) {
      videoRef.current.srcObject = props.user.getStream();
    }
  }, [props.user]);

  function onPlay() {
    setShowPlay(false);
    clearInterval(timerTryPlay.current);
  }

  function onCanPlay() {
    timerShowPLay.current = setTimeout(() => {
      if (videoRef.current.paused) {
        setShowPlay(true);
        timerTryPlay.current = setInterval(() => {
          videoRef.current.play();
        }, 1000);
      }
    }, 1000);
  }

  useEffect(() => {
    return () => {
      if (timerShowPLay.current !== undefined)
        clearTimeout(timerShowPLay.current);
      if (timerTryPlay.current !== undefined)
        clearInterval(timerTryPlay.current);
    };
  }, []);

  function play() {
    videoRef.current.play();
  }

  return (
    <div
      className={classNames('video-wrapper', {
        black: props.loading || props.user.isSlowInternetMode()
      })}
    >
      <video
        onPlay={onPlay}
        onCanPlay={onCanPlay}
        autoPlay
        playsInline
        loop
        id={
          props.user.isLocal() ? 'localVideo' : 'video-' + props.user.streamId
        }
        ref={videoRef}
        muted={props.user.isLocal() ? true : props.mutedSound}
        className={
          props.user.isLocal() && !props.user.isScreenShareActive()
            ? 'mirror'
            : ''
        }
      />
      {showPlay ? (
        <FontAwesomeIcon
          onClick={play}
          className='info-icon play'
          size='lg'
          icon={faPlay}
        />
      ) : (
        props.user.isSlowInternetMode() && (
          <FontAwesomeIcon
            className='info-icon video-muted'
            size='lg'
            icon={faWifi}
          />
        )
      )}
      {props.loading && (
        <CircularProgress className='loading-spinner' color='primary' />
      )}
    </div>
  );
}
