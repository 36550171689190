import React, { useCallback, useEffect, useReducer, useState } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

import { reducer as pollReducer, ACTIONS } from '../../reducers/timer'
import usePrevious from '../../hooks/usePrevious'
import useTimer from '../../hooks/useTimer'
import useEventEmitter from '../../hooks/useEventEmitter'
import InitTimerButton from '../TimerInitButton/InitTimerButton'
import { EVENTS } from '../../shared/constants/constants'
import ModalTimeChoice from './ModalTimeChoice/ModalTimeChoice'

import './Timer.scss'

dayjs.extend(duration)

const Timer = ({
  onTimerStatusChange,
  isAllowedToInitTimer,
  userId,
  eventEmitter,
  eventName
}) => {
  const [state, dispatch] = useReducer(pollReducer, {
    timerStatus: false,
    timerOwner: null,
    timestamp: 0,
    time: 0
  })
  const previousState = usePrevious(state)
  const [timer] = useTimer(state.timerStatus, state.time, state.timestamp)
  const [isModalOpened, setIsModalOpened] = useState(false)

  const canInitTimer =
    isAllowedToInitTimer &&
    userId &&
    (state.timerOwner === null || state.timerOwner === userId)

  const onTimerChangeStatusClick = (time) => {
    if (!state.timerStatus) {
      setIsModalOpened(true)
    }

    if (state.timerStatus) {
      dispatch({ type: ACTIONS.STOP_TIMER })
    }
  }

  const onInitCountdownClick = (time) => {
    dispatch({
      type: ACTIONS.INIT_TIMER,
      time: time,
      timestamp: dayjs().unix()
    })
    dispatch({ type: ACTIONS.SET_TIMER_OWNER, value: userId })
    setIsModalOpened(false)
  }

  const onReceiveData = useCallback((type, sender, msg) => {
    if (type === EVENTS.SET_TIMER_STATUS_EVENT) {
      dispatch({ type: ACTIONS.SET_TIMER_OWNER, value: sender })

      if (msg.timerStatus) {
        dispatch({
          type: ACTIONS.INIT_TIMER,
          time: msg.time,
          timestamp: msg.timestamp
        })
      }

      if (!msg.timerStatus) {
        dispatch({
          type: ACTIONS.STOP_TIMER
        })
      }
    }
  }, [])

  useEventEmitter(eventName, eventEmitter, onReceiveData)

  useEffect(() => {
    if (previousState && state !== previousState && state.timerOwner !== null) {
      if (canInitTimer) {
        onTimerStatusChange({
          time: state.time,
          timerStatus: state.timerStatus,
          timestamp: state.timestamp
        })
      }
    }
  }, [state, previousState, onTimerStatusChange, canInitTimer])

  useEffect(() => {
    if (timer === 0) {
      dispatch({
        type: ACTIONS.STOP_TIMER
      })
      dispatch({ type: ACTIONS.SET_TIMER_OWNER, value: null })
      onTimerStatusChange(null)
    }
  }, [timer, onTimerStatusChange])

  return (
    <div className='timer'>
      {canInitTimer && (
        <InitTimerButton
          canInitTimer={canInitTimer}
          onClick={onTimerChangeStatusClick}
          timerStatus={state.timerStatus}
        />
      )}
      <ModalTimeChoice
        isOpen={isModalOpened}
        onChoose={onInitCountdownClick}
        onClose={() => setIsModalOpened(false)}
      />
      <span className={timer <= 10000 && timer > 0 ? 'highlight' : ''}>
        {dayjs.duration(timer).format('mm:ss')}
      </span>
    </div>
  )
}

Timer.propTypes = {
  onTimerStatusChange: PropTypes.func.isRequired,
  isAllowedToInitTimer: PropTypes.bool.isRequired,
  userId: PropTypes.any,
  eventEmitter: PropTypes.any,
  eventName: PropTypes.string
}

export default Timer
