/* global alert */
/* global fetch */

import UserModel from './user-model';
import MessageManager from './message-manager';
import EventEmitter from 'wolfy87-eventemitter';
import moment from 'moment';
import WebrtcPlayer from './webrtc-player';
import WebrtcPublisher from './webrtc-publisher';
import DetectRTC from 'detectrtc';

const LIMIT_BANDWIDTH_SLOW_MODE = 246000;
const NUM_LIMIT_SLOW_MODE = 2;
const NUM_LIMIT_FAST_MODE = 2;

function perfilPulbisher(perfil) {
  return perfil === 'moderador' || perfil === 'apresentador';
}

class WebrtcRoom {
  constructor({ urlApi, urlPublish, urlPlay, token, checkConnection }) {
    this.urlPublish = urlPublish;
    this.urlPlay = urlPlay;
    this.urlApi = urlApi;
    this.token = token;
    this.checkConnection = checkConnection;
    this.perfil = null;
    this.streamId = null;
    this.roomId = null;
    this.userName = null;

    this.localUser = null;
    this.messageManager = null;
    this.webRTCPlayer = null;
    this.webRTCPublisher = null;

    this.streamMaster = null;

    this.otherUsers = [];

    this.eventManager = new EventEmitter();

    this.timerCheckPing = null;
    this.timerSendMe = null;
  }

  init = async () => {
    const usuario = await this.fetchUsuario();
    this.perfil = usuario.perfil;
    this.streamId = usuario.streamId;
    this.roomId = usuario.sala;
    this.userName = usuario.nome;
    this.messageManager = new MessageManager(this.streamId);

    this.initLocalUser();
    this.initPlayer();
    this.subscribeMessageManager();
    this.initTimers();
    window.addEventListener('beforeunload', this.cleanUp);
    if (perfilPulbisher(this.perfil)) {
      this.startPublish();
    }
  };

  fetchUsuario = async () => {
    const fet = await fetch(this.urlApi + '/usuario', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token
      }
    });
    if (fet.status !== 200) {
      console.error(await fet.text());
      alert('Erro ao capturar o usuário. Tente novamente mais tarde');
      return;
    }
    const usuario = await fet.json();

    return usuario;
  };

  refreshUsuario = async () => {
    const usuario = await this.fetchUsuario();

    const usuarioVelho = {
      perfil: this.perfil,
      streamId: this.streamId,
      roomId: this.roomId,
      userName: this.userName
    };

    this.perfil = usuario.perfil;
    this.userName = usuario.nome;

    if (usuarioVelho.perfil !== usuario.perfil) {
      if (
        perfilPulbisher(usuario.perfil) &&
        !perfilPulbisher(usuarioVelho.perfil)
      ) {
        this.startPublish();
      } else if (
        perfilPulbisher(usuarioVelho.perfil) &&
        !perfilPulbisher(usuario.perfil)
      ) {
        this.stopPublish();
      }
      this.emitPerfilChanged(usuario.perfil);
    }

    if (usuarioVelho.userName !== usuario.nome) {
      this.emitLocalUserChanged();
    }
  };

  initLocalUser = () => {
    this.localUser = new UserModel();
    this.localUser.setNickname(this.userName);
    this.localUser.setStreamId(this.streamId);
  };

  initTimers = () => {
    this.timerCheckPing = setInterval(() => {
      this.otherUsers.forEach((ou) => {
        if (
          !ou.isPublishing() &&
          moment(ou.getPingDate()) < moment().subtract(15, 's')
        ) {
          this.otherUsers.splice(this.otherUsers.indexOf(ou), 1);
        }
      });
      this.emitOtherUserChanged();
    }, 10000);

    this.timerSendMe = setInterval(() => {
      this.sendMe();
    }, 5000);
  };

  initPlayer = async () => {
    this.webRTCPlayer = new WebrtcPlayer({
      urlApi: this.urlApi,
      url: this.urlPlay,
      tokenApi: this.token,
      roomId: this.roomId,
      streamId: this.streamId,
      messageManager: this.messageManager,
      bitrateMeasurementCallback: this.bitrateMeasurement,
      updateActiveStreamsCallback: this.updateActiveStreams,
      startStreamCallback: this.startStream,
      stopStreamCallback: this.stopStream
    });
    this.webRTCPlayer.init();
  };

  updateActiveStreams = ({ streams }) => {
    if (!Array.isArray(streams)) return;
    streams.forEach((streamId) => {
      if (
        this.otherUsers.find((ou) => ou.getStreamId() === streamId) ===
        undefined
      ) {
        this.otherUsers.push(
          this.createOtherUser({ streamId, publishing: true })
        );
      }
    });

    this.otherUsers.forEach((ou) => {
      if (
        streams.find((streamId) => streamId === ou.getStreamId()) === undefined
      ) {
        if (ou.isPublishing()) {
          this.webRTCPlayer.stop(ou.getStreamId());
        }
        ou.setPublishing(false);
        ou.setStream(null);
      } else {
        ou.setPublishing(true);
      }
    });

    this.emitOtherUserChanged();
    this.selectStreamMaster({ streams });
  };

  startStream = ({ stream, streamId }) => {
    const findOtherUser = this.otherUsers.find(
      (ou) => ou.getStreamId() === streamId
    );
    findOtherUser.setStream(stream);

    this.emitOtherUserChanged();
  };

  stopStream = ({ streamId }) => {
    const ou = this.otherUsers.find((ou) => ou.getStreamId() === streamId);
    if (ou === undefined) {
      return;
    }
    ou.setPublishing(false);
    ou.setStream(null);
    this.emitOtherUserChanged();
  };

  stopPublish = () => {
    this.publisher = false;
    this.localUser.setPublishing(false);
    this.emitLocalUserChanged();
    this.emitPublishStopped();
    if (this.webRTCPublisher) {
      this.webRTCPublisher.kill();
    }
  };

  checkWebRtcPermission = async ({ successCallback, errorCallback }) => {
    DetectRTC.load(() => {
      if (
        DetectRTC.isWebsiteHasMicrophonePermissions &&
        DetectRTC.isWebsiteHasWebcamPermissions
      ) {
        successCallback();
      } else {
        errorCallback();
      }
    });
  };

  startPublish = async () => {
    this.checkWebRtcPermission({
      successCallback: () => {
        this.webRTCPublisher = new WebrtcPublisher({
          urlApi: this.urlApi,
          url: this.urlPublish,
          tokenApi: this.token,
          streamId: this.streamId,
          roomId: this.roomId,
          messageManager: this.messageManager,
          screenShareStoppedCallback: this.screenShareStopped,
          publishStartedCallback: this.publishStarted,
          showErrorMsgCallback: this.emitErrorMsg
        });
        this.webRTCPublisher.init();
      },
      errorCallback: () => {
        this.emitErrorMsg('MicCamNaoAutorizados');
      }
    });
  };

  publishStarted = () => {
    this.publisher = true;
    this.localUser.setPublishing(true);
    this.emitLocalUserChanged();
    this.emitPublishStarded();
    if (!this.localUser.isAudioActive()) {
      this.webRTCPublisher.muteLocalMic();
    }
    if (!this.localUser.isVideoActive()) {
      this.webRTCPublisher.turnOffLocalCamera();
    }
  };

  screenShareStopped = () => {
    this.localUser.setScreenShareActive(false);
    this.emitLocalUserChanged();
  };

  subscribeMessageManager = () => {
    this.messageManager.on('user-info', this.setOtherUserInfo);
    this.messageManager.on('send-msg', this.sendMsg);
    this.messageManager.on('send-custom-data', this.sendCustomData);
    this.messageManager.on('bye', this.removeUser);
    this.eventManager.on('other-users-changed', this.onOtherUserChanged);

    this.messageManager.on('receive-update-perfil', this.receiveUpdatePerfil);
    this.messageManager.on('receive-lower-hand', this.receiveLowerHand);
  };

  cleanUp = () => {
    this.sendBye();
    if (this.webRTCPlayer) this.webRTCPlayer.kill();

    this.stopPublish();

    this.messageManager.off('user-info', this.setOtherUserInfo);
    this.messageManager.off('send-msg', this.sendMsg);
    this.messageManager.off('send-custom-data', this.sendCustomData);
    this.messageManager.off('bye', this.removeUser);
    this.messageManager.off('receive-update-perfil', this.receiveUpdatePerfil);
    this.messageManager.off('receive-lower-hand', this.receiveLowerHand);

    window.removeEventListener('beforeunload', this.cleanUp);

    clearInterval(this.timerCheckPing);
    clearInterval(this.timerSendMe);
  };

  selectStreamMaster = ({ streams }) => {
    if (streams.length === 0) {
      this.streamMaster = null;
    } else {
      this.streamMaster = streams[0];
    }
  };

  createOtherUser = (data) => {
    const user = new UserModel(data);
    user.setType('remote');
    return user;
  };

  setOtherUserInfo = (streamId, info) => {
    const user = this.otherUsers.find((u) => u.getStreamId() === streamId);
    if (user === undefined) {
      this.otherUsers.push(this.createOtherUser(info));
    } else {
      user.fromObject(info);
      user.ping();
    }
    this.emitOtherUserChanged();
  };

  onOtherUserChanged = () => {
    this.otherUsers.forEach((ou) => {
      if (ou.getStream() === null && ou.isPublishing()) {
        this.webRTCPlayer.tryPlay(ou.getStreamId(), this.roomId);
      }
    });
  };

  getMessageManager = () => {
    return this.messageManager;
  };

  emitOtherUserChanged = () => {
    this.eventManager.emitEvent('other-users-changed', [this.otherUsers]);
  };

  emitLocalUserChanged = () => {
    this.eventManager.emitEvent('local-user-changed', [this.localUser]);
    this.sendMe();
  };

  emitErrorMsg = (error) => {
    this.eventManager.emitEvent('error', [error]);
  };

  emitPerfilChanged = () => {
    this.eventManager.emitEvent('perfil-changed', [this.perfil]);
  };

  emitPublishStarded = () => {
    this.eventManager.emitEvent('publish-started', []);
  };

  emitPublishStopped = () => {
    this.eventManager.emitEvent('publish-stopped', []);
  };

  sendData = (data) => {
    try {
      let streamId = null;
      let webRTC = null;
      if (this.publisher) {
        streamId = this.streamId;
        webRTC = this.webRTCPublisher;
      } else {
        streamId = this.streamMaster;
        webRTC = this.webRTCPlayer;
      }

      if (webRTC === undefined || streamId === null) return;

      console.info('Enviando webrtc', streamId, data);
      webRTC.sendData(streamId, JSON.stringify(data));
      return true;
    } catch (e) {
      console.warn(e);
      return false;
    }
  };

  sendMsg = (msg) => {
    this.sendData({
      type: 'SEND_MSG',
      sender: this.streamId,
      value: msg
    });
  };

  sendCustomData = (type, data) => {
    this.sendData({
      type: type,
      sender: this.streamId,
      value: data
    });
  };

  sendBye = () => {
    this.sendData({
      type: 'BYE',
      sender: this.streamId,
      value: null
    });
  };

  sendMe = () => {
    this.sendData({
      type: 'SET_ME',
      sender: this.streamId,
      value: this.localUser
    });
  };

  removeUser = (streamId) => {
    const findIndex = this.otherUsers.findIndex(
      (ou) => ou.getStreamId() === streamId
    );
    if (findIndex >= 0) {
      this.otherUsers.splice(findIndex, 1);
    }
    this.emitOtherUserChanged();
  };

  updatePerfil = async (user, perfil) => {
    const fet = await fetch(
      this.urlApi + '/usuario/' + user.getStreamId() + '/perfil',
      {
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.token
        },
        body: JSON.stringify({ perfil })
      }
    );
    if (fet.status !== 204) {
      console.error(await fet.text());
      alert('Erro ao capturar o token publish. Tente novamente mais tarde');
      return;
    }

    if (user.isLocal()) {
      this.refreshUsuario();
    } else {
      this.sendData({
        type: 'UPDATE_PERFIL',
        sender: this.streamId,
        value: { streamId: user.getStreamId() }
      });
    }
  };

  lowerHand = (user) => {
    if (user.isLocal()) {
      this.localUser.setHaiseHand(false);
      this.emitLocalUserChanged();
    } else {
      this.sendData({
        type: 'LOWER_HAND',
        sender: this.streamId,
        value: { streamId: user.getStreamId() }
      });
    }
  };

  receiveUpdatePerfil = (sender, { streamId }) => {
    if (this.localUser.getStreamId() === streamId) {
      this.refreshUsuario();
    }
  };

  receiveLowerHand = (sender, { streamId }) => {
    if (this.localUser.getStreamId() === streamId) {
      this.localUser.setHaiseHand(false);
      this.emitLocalUserChanged();
    }
  };

  toggleMuteLocalMic = () => {
    if (this.localUser.isAudioActive()) {
      this.webRTCPublisher.muteLocalMic();
      this.localUser.setAudioActive(false);
    } else {
      this.webRTCPublisher.unmuteLocalMic();
      this.localUser.setAudioActive(true);
    }
    this.emitLocalUserChanged();
  };

  toggleLocalCam = () => {
    if (this.localUser.isVideoActive()) {
      this.webRTCPublisher.turnOffLocalCamera();
    } else {
      this.webRTCPublisher.turnOnLocalCamera();
    }
    this.localUser.setVideoActive(!this.localUser.isVideoActive());
    this.emitLocalUserChanged();
  };

  toggleHaiseHand = () => {
    this.localUser.setHaiseHand(!this.localUser.getHaiseHand());
    this.emitLocalUserChanged();
  };

  screenShare = () => {
    if (this.localUser.isScreenShareActive()) {
      if (this.webRTCPublisher.getPublishMode() === 'screen') {
        this.webRTCPublisher.switchDesktopCaptureWithCamera(this.streamId);
      } else {
        this.webRTCPublisher.switchDesktopCapture(this.streamId);
      }
    } else {
      this.webRTCPublisher.switchDesktopCapture(this.streamId);
      this.localUser.setScreenShareActive(true);
      this.emitLocalUserChanged();
    }
  };

  stopScreenShare = () => {
    this.webRTCPublisher.switchVideoCameraCapture(this.streamId);
    this.localUser.setScreenShareActive(false);
    this.emitLocalUserChanged();
  };

  slowInternetMode = ({ user, ativo }) => {
    if (user.isSlowInternetMode() !== ativo) {
      this.webRTCPlayer.toggleVideo(
        user.getStreamId(),
        user.getStreamId(),
        !ativo
      );
      user.setSlowInternetMode(ativo);
      this.emitOtherUserChanged();
    }
  };

  bitrateMeasurement = ({ streamId, targetBitrate }) => {
    if (!this.checkConnection) return;
    const findOtherUser = this.otherUsers.find(
      (ou) => ou.getStreamId() === streamId
    );
    if (findOtherUser === undefined) return;

    if (targetBitrate < LIMIT_BANDWIDTH_SLOW_MODE) {
      findOtherUser.slowPing();
      if (findOtherUser.getNSlowPing() > NUM_LIMIT_SLOW_MODE) {
        this.slowInternetMode({ user: findOtherUser, ativo: true });
      }
    } else {
      findOtherUser.fastPing();
      if (findOtherUser.getNFastPing() > NUM_LIMIT_FAST_MODE) {
        this.slowInternetMode({ user: findOtherUser, ativo: false });
      }
    }
  };
}

export default WebrtcRoom;
