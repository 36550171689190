import React, { useEffect, useState } from 'react';
import './ToolbarComponent.css';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import Mic from '@material-ui/icons/Mic';
import MicOff from '@material-ui/icons/MicOff';
import Videocam from '@material-ui/icons/Videocam';
import VideocamOff from '@material-ui/icons/VideocamOff';
import Fullscreen from '@material-ui/icons/Fullscreen';
import FullscreenExit from '@material-ui/icons/FullscreenExit';
import PictureInPicture from '@material-ui/icons/PictureInPicture';
import ScreenShare from '@material-ui/icons/ScreenShare';
import StopScreenShare from '@material-ui/icons/StopScreenShare';
import Tooltip from '@material-ui/core/Tooltip';
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';
import QuestionAnswer from '@material-ui/icons/QuestionAnswer';
import People from '@material-ui/icons/People';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import PanToolIcon from '@material-ui/icons/PanTool';
import screenfull from 'screenfull';

export default function ToolbarComponent(props) {
  const [fullscreen, setFullscreen] = useState(false);

  function micStatusChanged() {
    props.micStatusChanged();
  }

  function camStatusChanged() {
    props.camStatusChanged();
  }
  function haiseHandChanged() {
    props.haiseHandChanged();
  }

  function screenShare() {
    props.screenShare();
  }

  function stopScreenShare() {
    props.stopScreenShare();
  }

  function leaveSession() {
    props.leaveSession();
  }

  function toggleChat() {
    props.toggleChat();
  }

  function toggleParticipants() {
    props.toggleParticipants();
  }

  function toggleFullscreen() {
    if (screenfull.isEnabled) {
      screenfull.toggle();
    }
  }

  useEffect(() => {
    if (!screenfull.onchange) {
      return;
    }
    screenfull.onchange(() => {
      setFullscreen(screenfull.isFullscreen);
    });

    return () => screenfull.off('onchange');
  }, []);

  const localUser = props.user;
  const publisher = props.publisher;
  const participantsCount = props.participantsCount;
  const chatCount = props.chatCount;

  return (
    <AppBar className='toolbar' id='footer' color='primary'>
      <Toolbar className='toolbar'>
        <div className='buttonsContent'>
          <div className='buttonsLeft'>
            {publisher && (
              <IconButton
                color='inherit'
                className='navButton'
                id='navMicButton'
                onClick={micStatusChanged}
              >
                {localUser !== null && localUser.isAudioActive() ? (
                  <Tooltip title='Microfone Mudo'>
                    <Mic />
                  </Tooltip>
                ) : (
                  <Tooltip title='Microfone Aberto'>
                    <MicOff color='secondary' />
                  </Tooltip>
                )}
              </IconButton>
            )}
            {publisher && (
              <IconButton
                color='inherit'
                className='navButton'
                id='navCamButton'
                onClick={camStatusChanged}
              >
                {localUser !== null && localUser.isVideoActive() ? (
                  <Tooltip title='Esconder Câmera'>
                    <Videocam />
                  </Tooltip>
                ) : (
                  <Tooltip title='Mostrar Câmera'>
                    <VideocamOff color='secondary' />
                  </Tooltip>
                )}
              </IconButton>
            )}
          </div>
          <div className='buttonsCenter'>
            <IconButton
              color='inherit'
              onClick={toggleChat}
              className='navButton'
              id='navChatButton'
            >
              <Tooltip title='Bate Papo'>
                <Badge badgeContent={chatCount} color='primary'>
                  <QuestionAnswer />
                </Badge>
              </Tooltip>
            </IconButton>
            <IconButton
              color='inherit'
              id='navParticipantsButton'
              className='navButton'
              onClick={toggleParticipants}
            >
              <Tooltip title='Participantes'>
                <Badge badgeContent={participantsCount} color='primary'>
                  <People />
                </Badge>
              </Tooltip>
            </IconButton>
            <IconButton
              color='inherit'
              className='navButton'
              onClick={haiseHandChanged}
            >
              {localUser !== null && localUser.getHaiseHand() ? (
                <Tooltip title='Abaixar a mão'>
                  <PanToolIcon color='secondary' />
                </Tooltip>
              ) : (
                <Tooltip title='Levantar a mão'>
                  <PanToolIcon />
                </Tooltip>
              )}
            </IconButton>
            {publisher && (
              <>
                <IconButton
                  color='inherit'
                  className='navButton'
                  onClick={screenShare}
                >
                  {localUser !== null && localUser.isScreenShareActive() ? (
                    <Tooltip title='Video e Tela'>
                      <PictureInPicture />
                    </Tooltip>
                  ) : (
                    <Tooltip title='Compartilhar Tela'>
                      <ScreenShare />
                    </Tooltip>
                  )}
                </IconButton>

                {localUser !== null && localUser.isScreenShareActive() && (
                  <IconButton onClick={stopScreenShare} id='navScreenButton'>
                    <Tooltip title='Parar Compartilhamento de Tela'>
                      <StopScreenShare color='secondary' />
                    </Tooltip>
                  </IconButton>
                )}
              </>
            )}
            <IconButton
              color='inherit'
              className='navButton'
              onClick={toggleFullscreen}
            >
              {localUser !== null && fullscreen ? (
                <Tooltip title='Fechar Tela Cheia'>
                  <FullscreenExit />
                </Tooltip>
              ) : (
                <Tooltip title='Tela Cheia'>
                  <Fullscreen />
                </Tooltip>
              )}
            </IconButton>
          </div>

          <div className='buttonsRight'>
            {props.children}
            <IconButton
              color='secondary'
              className='navButton'
              onClick={leaveSession}
              id='navLeaveButton'
            >
              <Tooltip title='Sair'>
                <PowerSettingsNew />
              </Tooltip>
            </IconButton>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
}
