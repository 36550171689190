/* global Event */
import WebRTCAdaptorBase from '@libs/webrtc-antmedia';

export default class WebRTCAdaptor extends WebRTCAdaptorBase {
  constructor(
    url,
    playMode,
    {
      initializedCallback,
      joinedTheRoomCallback,
      newStreamAvailableCallback,
      roomInformationCallback,
      dataChannelOpenedCallback,
      playFinishedCallback,
      dataReceivedCallback,
      publishStartedCallback,
      screenShareStoppedCallback,
      iceConnectionStateChangedCallback,
      bitrateMeasurementCallback,
      errorScreenSharePermissionDeniedCallback,
      errorGenericCallback
    }
  ) {
    const mediaConstraints = {
      video: {
        width: { ideal: 320 },
        height: { ideal: 240 },
        frameRate: { ideal: 20 }
      },
      audio: {
        echoCancellation: true,
        noiseSuppression: true
      }
    };
    const sdpConstraints = {
      OfferToReceiveAudio: false,
      OfferToReceiveVideo: false
    };

    const pcConfig = {
      iceServers: [
        {
          urls: 'stun:stun1.l.google.com:19302'
        },
        {
          urls: [
            'turn:turn.webrtc.panagora.com.br:8080',
            'turn:turn.webrtc.panagora.com.br:8080?transport=tcp'
          ],
          username: 'pandora',
          credential: 'completo'
        }
      ]
    };

    super({
      bandwidth: 150,
      websocket_url: url,
      mediaConstraints: mediaConstraints,
      peerconnection_config: pcConfig,
      sdp_constraints: sdpConstraints,
      localVideoId: 'localVideo',
      isPlayMode: playMode,
      debug: false,
      callback: (info, obj) => {
        console.info('Webrtc recebido', playMode, info, obj);
        switch (info) {
          case 'initialized':
            if (initializedCallback !== undefined) {
              initializedCallback(obj);
            }
            this.dateLastPong = new Date();
            break;
          case 'ice_connection_state_changed':
            if (iceConnectionStateChangedCallback !== undefined) {
              iceConnectionStateChangedCallback(obj);
            }
            break;
          case 'joinedTheRoom':
            if (joinedTheRoomCallback !== undefined) {
              joinedTheRoomCallback(obj);
            }
            break;
          case 'newStreamAvailable':
            if (newStreamAvailableCallback !== undefined) {
              newStreamAvailableCallback(obj);
            }
            break;
          case 'roomInformation':
            if (roomInformationCallback !== undefined) {
              roomInformationCallback(obj);
            }
            break;
          case 'data_channel_opened':
            if (dataChannelOpenedCallback !== undefined) {
              dataChannelOpenedCallback(obj);
            }
            break;
          case 'play_finished':
            if (playFinishedCallback !== undefined) {
              playFinishedCallback(obj);
            }
            break;
          case 'data_received':
            if (dataReceivedCallback !== undefined) {
              dataReceivedCallback(JSON.parse(obj.event.data));
            }
            break;
          case 'publish_started':
            if (publishStartedCallback !== undefined) {
              publishStartedCallback(obj);
            }
            break;
          case 'screen_share_stopped':
            if (screenShareStoppedCallback !== undefined) {
              screenShareStoppedCallback(obj);
            }
            break;
          case 'pong':
            this.dateLastPong = new Date();
            break;
          case 'bitrateMeasurement':
            if (bitrateMeasurementCallback !== undefined) {
              bitrateMeasurementCallback(obj);
            }
            break;
          default:
            break;
        }
      },
      callbackError: function (error, message) {
        console.error('erro', error, message);
        if (error instanceof Event) {
          if (errorGenericCallback !== undefined) {
            errorGenericCallback();
          }
        }
        switch (error) {
          case 'ScreenSharePermissionDenied':
            if (errorScreenSharePermissionDeniedCallback !== undefined) {
              errorScreenSharePermissionDeniedCallback();
            }
            break;
          default:
            if (errorGenericCallback !== undefined) {
              errorGenericCallback(error, message);
            }
            break;
        }
      }
    });

    this.dateLastPlay = [];
    this.dateLastPong = null;
    this.timerPongCheck = setInterval(() => {
      let state = null;
      if (this.webSocketAdaptor) {
        state = this.webSocketAdaptor.wsConn.readyState;
      } else {
        state = 3;
      }
      console.info('pong check', this.isPlayMode, state);
      if (
        state === 3 ||
        (this.dateLastPong !== null &&
          this.dateLastPong < Date.now() - 10000 &&
          state === 1)
      ) {
        console.info(
          'Resetando socket',
          this.isPlayMode,
          state,
          this.dateLastPong
        );
        this.resetWebSocket();
      }
    }, 5000);
  }

  stopAll() {
    this.playStreamId.forEach((streamId) => {
      this.stop(streamId);
    });
  }

  tryPlay(streamId, token, roomId, enableTracks, subscriberId, subscriberCode) {
    const timeLastPlay = this.dateLastPlay[streamId];
    if (timeLastPlay === undefined || timeLastPlay < Date.now() - 10000) {
      this.dateLastPlay[streamId] = new Date();
      return this.play(
        streamId,
        token,
        roomId,
        enableTracks,
        subscriberId,
        subscriberCode
      );
    }
  }

  kill(streamId, stop) {
    if (stop) {
      try {
        this.stop(streamId);
      } catch (e) {
        console.warn(e);
      }
    }
    try {
      this.closeStream();
    } catch (e) {
      console.warn(e);
    }
    try {
      this.closeWebSocket();
    } catch (e) {
      console.warn(e);
    }
    clearInterval(this.timerPongCheck);
  }

  switchDesktopCaptureWithCamera = (streamId) => {
    const origMediaConstraint = { ...this.mediaConstraints };
    this.mediaConstraints.video = true;
    super.switchDesktopCaptureWithCamera(streamId);
    this.mediaConstraints = origMediaConstraint;
  };

  switchDesktopCapture = (streamId) => {
    const origMediaConstraint = { ...this.mediaConstraints };
    this.mediaConstraints.video = true;
    super.switchDesktopCapture(streamId);
    this.mediaConstraints = origMediaConstraint;
  };

  resetWebSocket = () => {
    if (this.webSocketAdaptor) {
      this.webSocketAdaptor.close();
      this.webSocketAdaptor.clearPingTimer();
    }
    this.webSocketAdaptor = null;
    super.checkWebSocketConnection();
    this.dateLastPong = null;
  };
}
