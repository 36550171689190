import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import DraggableDialog from '../../DraggableModal/DraggableModal'

import './ModalResults.scss'
import {
  ThumbDownRounded,
  ThumbUpRounded,
  ThumbsUpDownRounded
} from '@material-ui/icons'

import { Typography } from '@material-ui/core'

const ModalResults = ({
  pollVotesList,
  isOpen,
  onClose,
  totalUsersOnlineDuringPoll
}) => {
  const [results, setResults] = useState({})

  useEffect(() => {
    const totalVotesList = Array.from(pollVotesList, ([name, value]) => value)

    const summarizedVotes = totalVotesList.reduce(
      (acc, value) => acc.set(value, (acc.get(value) || 0) + 1),
      new Map()
    )

    setResults(summarizedVotes)
  }, [pollVotesList])

  const getVotes = (option) => {
    return results.get(option) ?? 0
  }

  const getVotesWithLabel = (option) => {
    const numberOfVotes = getVotes(option)

    const label = getVotes(option) === 1 ? 'Voto' : 'Votos'

    return `${numberOfVotes} ${label}`
  }

  const getTotalVotes = () => {
    let numberOfVotes = 0

    if (results.get) {
      results.forEach((optionVotes) => {
        numberOfVotes += optionVotes
      })
    }

    return numberOfVotes
  }

  const getRoundedPercentageOfVotes = (numberOfVotes) => {
    const votes = (numberOfVotes / getTotalVotes()) * 100
    return Math.round(votes * 100) / 100
  }

  return (
    <DraggableDialog
      title='Resultado da Votação'
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className='modalResultsContent'>
        {results.get && (
          <>
            <div className='voteButtonWrapper'>
              <div className='voteYes'>
                <span className='voteIcon'>
                  <ThumbUpRounded fontSize='large' />
                </span>
                <Typography variant='h5'>{getVotesWithLabel('Sim')}</Typography>
                <Typography variant='body'>
                  {getRoundedPercentageOfVotes(getVotes('Sim'))}%
                </Typography>
              </div>
              <div className='voteBlank'>
                <span className='voteIcon'>
                  <ThumbsUpDownRounded fontSize='large' />
                </span>
                <Typography variant='h5'>
                  {getVotesWithLabel('Abstenção')}
                </Typography>
                <Typography variant='body'>
                  {getRoundedPercentageOfVotes(getVotes('Abstenção'))}%
                </Typography>
              </div>
              <div className='voteNo'>
                <span className='voteIcon'>
                  <ThumbDownRounded fontSize='large' />
                </span>
                <Typography variant='h5'>{getVotesWithLabel('Não')}</Typography>
                <Typography variant='body'>
                  {getRoundedPercentageOfVotes(getVotes('Não'))}%
                </Typography>
              </div>
            </div>
          </>
        )}
      </div>
      <Typography variant='body1'>Total de Votos: {getTotalVotes()}</Typography>
      <Typography variant='body1'>
        Total de Participantes: {totalUsersOnlineDuringPoll}
      </Typography>
      <Typography variant='body1'>
        {`Participação: ${Math.round(
          (getTotalVotes() / totalUsersOnlineDuringPoll) * 100
        )}%`}
      </Typography>
    </DraggableDialog>
  )
}

ModalResults.propTypes = {
  pollVotesList: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  totalUsersOnlineDuringPoll: PropTypes.number.isRequired
}

export default ModalResults
