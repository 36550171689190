import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import PropTypes from 'prop-types'
import Modal from 'react-bootstrap/Modal'
import comprovante from '../../api/comprovante'

export function Sucesso() {
  return (
    <div>
      <h4 className="text-success">Comprovante enviado com sucesso</h4>
      <p>
        Verifique sua caixa de entrada em alguns minutos, caso não encontre
        verifique seu spam.
      </p>
    </div>
  )
}

function EnviarComprovanteModal({
  urlApiAssembleia,
  userToken,
  comprovanteHash,
  emailDefault,
  eventoId,
  onFechar,
  open
}) {
  const [email, setEmail] = useState(emailDefault)
  const [ajaxValidation, setAjaxValidation] = useState(null)
  const [validated, setValidated] = useState(false)
  const [loading, setLoading] = useState(false)
  const [sucesso, setSucesso] = useState(false)

  useEffect(() => {
    if (!open) return
    setSucesso(false)
    setLoading(false)
    setValidated(false)
    setAjaxValidation(null)
    setEmail(emailDefault)
  }, [open, emailDefault])

  const handleChange = (event) => {
    setEmail(event.target.value)
    setAjaxValidation(null)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (loading) {
      return
    }
    const form = event.currentTarget

    setValidated(false)

    if (form.checkValidity() === false) {
      event.stopPropagation()
      setValidated(true)
      return
    }
    setLoading(true)
    const ret = await comprovante.enviarComprovante({
      urlApiAssembleia,
      userToken,
      eventoId,
      email,
      comprovante: comprovanteHash
    })
    setLoading(false)

    if (ret.success) {
      setSucesso(true)
    } else {
      switch (ret.status) {
        case 422:
          setAjaxValidation(ret.data)
          break

        default:
          alert(ret.data.mensagem)
          break
      }
    }
  }

  return (
    <Modal onHide={onFechar} show={open}>
      <Modal.Header closeButton>
        <Modal.Title>Enviar Comprovante</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {sucesso ? (
          <Sucesso />
        ) : (
          <Form
            noValidate
            validated={validated}
            id="formEnviarComprovante"
            onSubmit={handleSubmit}
          >
            <Form.Group controlId="formEmail">
              <Form.Label>
                Digite o e-mail para receber o comprovante
              </Form.Label>
              <Form.Control
                required
                name="email"
                isInvalid={ajaxValidation}
                onChange={handleChange}
                value={email}
                maxLength={100}
                type="email"
                placeholder="seunome@provedor.com.br"
              />
              <Form.Control.Feedback type="invalid">
                E-mail inválido
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        {sucesso ? (
          <Button
            onClick={() => {
              onFechar()
            }}
            size="lg"
            variant="primary"
          >
            FECHAR
          </Button>
        ) : (
          <div>
            <Button
              onClick={() => {
                onFechar()
              }}
              size="lg"
              variant="secondary"
              className="mx-1"
            >
              CANCELAR
            </Button>
            <Button
              type="submit"
              form="formEnviarComprovante"
              disabled={loading}
              size="lg"
              variant="primary"
              className="mx-1"
            >
              ENVIAR
            </Button>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  )
}

EnviarComprovanteModal.propTypes = {
  urlApiAssembleia: PropTypes.string.isRequired,
  userToken: PropTypes.string.isRequired,
  comprovanteHash: PropTypes.string.isRequired,
  emailDefault: PropTypes.string,
  eventoId: PropTypes.number.isRequired,
  onFechar: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
}
EnviarComprovanteModal.defaultProps = {
  emailDefault: ''
}

export default EnviarComprovanteModal
