async function enviarComprovante({
  urlApiAssembleia,
  userToken,
  eventoId,
  email,
  comprovante
}) {
  const url = new URL(
    `${urlApiAssembleia}/eventos/${eventoId}/enviar-comprovante`
  )

  const fet = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`
    },
    body: JSON.stringify({ email, comprovante })
  })

  switch (fet.status) {
    case 204:
      return { success: true, status: fet.status, data: null }
    case 400:
    case 401:
    case 403:
    case 404:
    case 422:
      try {
        return { success: false, status: fet.status, data: await fet.json() }
      } catch (e) {
        return {
          success: false,
          status: fet.status,
          data: { mensagem: 'Erro ao enviar comprovante por e-mail' }
        }
      }
    default:
      alert('Ops... tente novamente mais tarde')
      console.error(await fet.text())
      break
  }
  return null
}
const comprovante = { enviarComprovante }

export default comprovante
