import React, { useEffect, useRef, useState } from 'react';
import WebrtcRoom from './webrtc-room';
import clone from 'lodash/clone';
import AlertDialog from './dialog-msg';
import NotAllowedDialog from './dialog-not-allowed';
import Container from '@material-ui/core/Container';

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

const WithWebRtc = (Component) => {
  const Comp = (props) => {
    const checkConnection =
      props.checkConnection === undefined ? true : props.checkConnection;
    const [localUser, setLocalUser] = useState(null);

    const [otherUsers, setOtherUsers] = useState([]);

    const [perfil, setPefil] = useState(null);
    const [publisher, setPublisher] = useState(false);

    const webRtcRoom = useRef(null);

    const [messageManager, setMessageManager] = useState(null);
    const [errorMsg, setErrorMsg] = useState({
      open: false,
      msg: null,
      titulo: null
    });
    const [errorNotAllowed, setErrorNotAllowed] = useState(false);
    const [errorPanic, setErrorPanic] = useState({
      open: false,
      msg: null
    });
    const onErrorCallback = props.onError;

    useEffect(() => {
      webRtcRoom.current = new WebrtcRoom({
        urlApi: props.urlApi,
        urlPublish: props.urlPublisher,
        urlPlay: props.urlPlayer,
        token: props.token,
        checkConnection: checkConnection
      });
      webRtcRoom.current.init().then(() => {
        setMessageManager(webRtcRoom.current.getMessageManager());
        setLocalUser(webRtcRoom.current.localUser);
        setPefil(webRtcRoom.current.perfil);
      });

      webRtcRoom.current.eventManager.on(
        'other-users-changed',
        onOtherUsersChanged
      );
      webRtcRoom.current.eventManager.on(
        'local-user-changed',
        onLocalUserChanged
      );
      webRtcRoom.current.eventManager.on('perfil-changed', onPerfilChanged);

      webRtcRoom.current.eventManager.on('publish-started', onInitPublish);
      webRtcRoom.current.eventManager.on('publish-stopped', onStopPublish);

      return () => {
        webRtcRoom.current.eventManager.off(
          'other-users-changed',
          onOtherUsersChanged
        );
        webRtcRoom.current.eventManager.off(
          'local-user-changed',
          onLocalUserChanged
        );
        webRtcRoom.current.eventManager.off('perfil-changed', onPerfilChanged);
        webRtcRoom.current.eventManager.off('publish-started', onInitPublish);
        webRtcRoom.current.eventManager.off('publish-stopped', onStopPublish);
        webRtcRoom.current.cleanUp();
      };
    }, [
      props.urlPublisher,
      props.urlPlayer,
      props.token,
      props.urlApi,
      checkConnection
    ]);

    useEffect(() => {
      const openErro = (erro) => {
        if (onErrorCallback) {
          onErrorCallback(erro);
        }

        switch (erro) {
          case 'MicCamNaoAutorizados':
            setErrorNotAllowed(true);
            break;
          case 'NotAllowedError':
            setErrorMsg({
              open: true,
              msg: 'Seu microfone ou câmera foi bloqueado, tente novamente atualizando a página.',
              titulo: 'Microfone ou Câmera bloqueados'
            });
            break;
          case 'NotReadableError':
            setErrorMsg({
              open: true,
              msg: 'Não foi possível acessar a câmera. Verifique se ela não está em uso por outro aplicativo',
              titulo: 'Erro ao acessar a câmera ou microfone'
            });
            break;
          case 'streamIdInUse':
            webRtcRoom.current.cleanUp();
            setErrorPanic({
              open: true,
              msg: 'Você já esta com esta sala aberta em outra janela do navegador'
            });
            break;
          default:
            break;
        }
      };
      webRtcRoom.current.eventManager.on('error', openErro);

      return () => {
        webRtcRoom.current.eventManager.off('error', openErro);
      };
    }, [onErrorCallback]);

    function onOtherUsersChanged(ous) {
      setOtherUsers([...ous]);
    }

    function onLocalUserChanged(lu) {
      setLocalUser(clone(lu));
    }

    function onPerfilChanged(perfil) {
      setPefil(perfil);
    }

    function onInitPublish() {
      setPublisher(true);
    }

    function onStopPublish() {
      setPublisher(false);
    }

    function toggleMuteLocalMic() {
      webRtcRoom.current.toggleMuteLocalMic();
    }

    function toggleLocalCam() {
      webRtcRoom.current.toggleLocalCam();
    }

    function screenShare() {
      webRtcRoom.current.screenShare();
    }

    function stopScreenShare() {
      webRtcRoom.current.stopScreenShare();
    }

    function updatePerfil(user, perfil) {
      webRtcRoom.current.updatePerfil(user, perfil);
    }

    function toggleHaiseHand() {
      webRtcRoom.current.toggleHaiseHand();
    }

    function lowerHand(user) {
      webRtcRoom.current.lowerHand(user);
    }

    if (errorPanic.open) {
      return (
        <Container maxWidth='sm'>
          <h1>{errorPanic.msg}</h1>
        </Container>
      );
    }
    return (
      <>
        <AlertDialog
          open={errorMsg.open}
          handleClose={() => {
            setErrorMsg({ open: false });
          }}
          msg={errorMsg.msg}
          titulo={errorMsg.titulo}
        />
        <NotAllowedDialog
          open={errorNotAllowed}
          handleClose={() => {
            webRtcRoom.current.startPublish();
            setErrorNotAllowed(false);
          }}
          messageManager={messageManager}
          localUser={localUser}
        />
        <Component
          {...props}
          localUser={localUser}
          otherUsers={otherUsers}
          perfil={perfil}
          publisher={publisher}
          toggleMuteLocalMic={toggleMuteLocalMic}
          toggleLocalCam={toggleLocalCam}
          toggleHaiseHand={toggleHaiseHand}
          screenShare={screenShare}
          stopScreenShare={stopScreenShare}
          updatePerfil={updatePerfil}
          lowerHand={lowerHand}
          messageManager={messageManager}
        />
      </>
    );
  };
  Comp.displayName = `WithWebRtc(${getDisplayName(Component)})`;
  return Comp;
};

export default WithWebRtc;
