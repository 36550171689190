import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { ONE_SECOND_TIME } from '../shared/constants/constants'
import usePrevious from './usePrevious'

const useTimer = (timerStatus, timeInMilliseconds, timestamp) => {
  const previousStatus = usePrevious(timerStatus)
  const [time, setTime] = useState()

  useEffect(() => {
    if (time === 0) {
      return
    }

    const setTimerInterval = setInterval(() => {
      setTime((previousTime) => previousTime - ONE_SECOND_TIME)
    }, ONE_SECOND_TIME)

    return () => {
      clearInterval(setTimerInterval)
    }
  }, [time])

  useEffect(() => {
    if (timerStatus !== previousStatus && !timerStatus) {
      setTime(0)
    }
  }, [timerStatus, previousStatus])

  useEffect(() => {
    if (timerStatus !== previousStatus && timerStatus) {
      const now = dayjs().unix() * ONE_SECOND_TIME
      const endTimeStamp = timestamp * ONE_SECOND_TIME + timeInMilliseconds

      if (endTimeStamp > now) {
        setTime(endTimeStamp - now)
      }
    }
  }, [timerStatus, previousStatus, timestamp, timeInMilliseconds])

  return [time, setTime]
}

export default useTimer
