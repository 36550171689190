import React, { useEffect, useRef, useState } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CancelIcon from '@material-ui/icons/Cancel';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import PanToolIcon from '@material-ui/icons/PanTool';

import './ParticipantsList.css';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import classNames from 'classnames';

export default function ParticipantsList(props) {
  const user = props.user;
  const [sortedList, setSortedList] = useState([]);
  const sortParticipantsHandle = props.sortParticipantsHandle;

  useEffect(() => {
    const users = [...props.otherUsers];
    users.push(user);
    if (sortParticipantsHandle) {
      setSortedList(sortParticipantsHandle(users));
    } else {
      setSortedList(sortListDefaultFunc(users));
    }
  }, [props.otherUsers, sortParticipantsHandle, user]);

  function close() {
    props.closeHandler();
  }

  function togglePublishHandler(user) {
    if (user.isPublishing()) {
      props.updatePerfil(user, 'espectador');
    } else {
      props.updatePerfil(user, 'apresentador');
    }
  }

  function lowerHand(user) {
    props.lowerHand(user);
  }

  return (
    <div className='participants-list'>
      <AppBar position='relative' color='secondary'>
        <Toolbar variant='dense'>
          Participantes
          <IconButton onClick={close} className='close-button' color='inherit'>
            <CancelIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <List dense className='list-part'>
        {sortedList.map((group, i) => (
          <React.Fragment key={i}>
            <Grupo
              list={group.itens}
              lowerHand={lowerHand}
              togglePublishHandler={togglePublishHandler}
              moderator={props.moderator}
              customAction={props.customAction}
            />
          </React.Fragment>
        ))}
      </List>
    </div>
  );
}

function Grupo({
  list,
  lowerHand,
  togglePublishHandler,
  moderator,
  customAction
}) {
  const itens = list.map((ou) => (
    <Item
      nickname={ou?.getNickname()}
      key={ou?.getStreamId()}
      moderator={moderator}
      publisher={ou?.isPublishing()}
      handHaised={ou?.getHaiseHand()}
      lowerHand={() => {
        lowerHand(ou);
      }}
      togglePublishHandler={() => {
        togglePublishHandler(ou);
      }}
      customAction={customAction}
    >
      {customAction && customAction(ou)}
    </Item>
  ));

  if (itens.length > 0) {
    return (
      <>
        {itens}
        <Divider />
      </>
    );
  } else {
    return null;
  }
}

function Item(props) {
  const [loadingPublisher, setLoadingPublisher] = useState(false);
  const [loadingLowerHand, setLoadingLowerHand] = useState(false);

  const timerPublisher = useRef();
  const timerLowerHand = useRef();

  function onClickPublisher() {
    props.togglePublishHandler();
    setLoadingPublisher(true);

    clearTimeout(timerPublisher.current);
    timerPublisher.current = setTimeout(() => {
      setLoadingPublisher(false);
    }, 20000);
  }

  function onClickLowerHand() {
    props.lowerHand();
    setLoadingLowerHand(true);

    clearTimeout(timerLowerHand.current);
    timerLowerHand.current = setTimeout(() => {
      setLoadingLowerHand(false);
    }, 20000);
  }

  useEffect(() => {
    setLoadingPublisher(false);
  }, [props.publisher]);

  useEffect(() => {
    setLoadingLowerHand(false);
  }, [props.handHaised]);

  useEffect(() => {
    return () => {
      clearTimeout(timerPublisher.current);
      clearTimeout(timerLowerHand.current);
    };
  }, []);

  return (
    <ListItem className={classNames({ haisedHand: props.handHaised })}>
      <ListItemAvatar>
        <Avatar>{props.nickname.trim().charAt(0)}</Avatar>
      </ListItemAvatar>
      <ListItemText primary={props.nickname} />
      <ListItemSecondaryAction>
        {props.children}
        {loadingLowerHand ? (
          <CircularProgress className='loading-action' size={24} />
        ) : (
          props.handHaised &&
          (props.moderator ? (
            <IconButton onClick={onClickLowerHand} color='primary'>
              <PanToolIcon />
            </IconButton>
          ) : (
            <Icon color='primary'>
              <PanToolIcon />
            </Icon>
          ))
        )}
        {loadingPublisher ? (
          <CircularProgress className='loading-action' size={24} />
        ) : props.moderator ? (
          <IconButton
            onClick={onClickPublisher}
            color={props.publisher ? 'primary' : 'default'}
          >
            <RecordVoiceOverIcon />
          </IconButton>
        ) : (
          <Icon color={props.publisher ? 'primary' : 'default'}>
            <RecordVoiceOverIcon />
          </Icon>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
}

function sortListDefaultFunc(users) {
  const ordered = users.sort((a, b) => {
    if (a.getNickname() < b.getNickname()) {
      return -1;
    }
    if (a.getNickname() > b.getNickname()) {
      return 1;
    }
    return 0;
  });
  return [{ grupo: 'Unico', itens: ordered }];
}
