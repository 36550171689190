import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'

import './DraggableModal.scss'
import Paper from './Paper/Paper'
import DialogTitle from './Title/Title'

const DraggableModal = ({ children, title, isOpen, onClose, width }) => {
  return (
    <Dialog
      className='draggableDialog'
      open={isOpen}
      onClose={onClose}
      PaperComponent={Paper}
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
    >
      {title && <DialogTitle onClose={onClose}>{title}</DialogTitle>}
      <DialogContent>{children}</DialogContent>
    </Dialog>
  )
}

DraggableModal.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
}

export default DraggableModal
