import React, { useEffect, useRef, useState } from 'react';
import './VideoRoom.css';
import StreamComponent from './stream/StreamComponent';
import ChatComponent from './chat/ChatComponent';

import Layout from './layout';
import ToolbarComponent from './toolbar/ToolbarComponent';
import PropTypes from 'prop-types';
import WithWebRtc from './WithWebRtc';
import classNames from 'classnames';
import ParticipantsList from './participants/ParticipantsList';

function VideoRoom(props) {
  const layout = useRef();

  const [showParticipants, setShowParticipants] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [chatCount, setChatCount] = useState(0);
  const [publishing, setPublishing] = useState(false);
  const {
    onReceiveMessage,
    setMessageManager,
    onSetUserProfile,
    onSetUser,
    onSetOtherUsers
  } = props;

  const publisher =
    props.perfil === 'moderador' || props.perfil === 'apresentador';

  useEffect(() => {
    updateLayout();
  }, [props.localUser, props.otherUsers]);

  useEffect(() => {
    updateLayout();

    if (props.perfil && onSetUserProfile) {
      onSetUserProfile(props.perfil);
    }
  }, [props.perfil, onSetUserProfile]);

  useEffect(() => {
    if (props.otherUsers && onSetOtherUsers) {
      onSetOtherUsers(props.otherUsers);
    }
  }, [props.otherUsers, onSetOtherUsers]);

  useEffect(() => {
    if (props.localUser && onSetUser) {
      onSetUser(props.localUser);
    }
  }, [props.localUser, onSetUser]);

  useEffect(() => {
    let timer = null;
    if (props.publisher) {
      setPublishing(false);
      timer = setTimeout(() => {
        setPublishing(true);
      }, 5000);
    } else {
      setPublishing(false);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [props.publisher]);

  useEffect(() => {
    layout.current = new Layout();

    const layoutOptions = {
      maxRatio: 3 / 2, // The narrowest ratio that will be used (default 2x3)
      minRatio: 9 / 16, // The widest ratio that will be used (default 16x9)
      fixedRatio: false, // If this is true then the aspect ratio of the video is maintained and minRatio and maxRatio are ignored (default false)
      bigClass: 'OV_big', // The class to add to elements that should be sized bigger
      bigPercentage: 0.8, // The maximum percentage of space the big ones should take up
      bigFixedRatio: false, // fixedRatio for the big ones
      bigMaxRatio: 3 / 2, // The narrowest ratio to use for the big elements (default 2x3)
      bigMinRatio: 9 / 16, // The widest ratio to use for the big elements (default 16x9)
      bigFirst: true, // Whether to place the big one in the top left (true) or bottom right
      animate: true // Whether you want to animate the transitions
    };

    layout.current.initLayoutContainer(
      document.getElementById('layout'),
      layoutOptions
    );
    window.addEventListener('resize', updateLayout);

    return () => {
      window.removeEventListener('resize', updateLayout);
    };
  }, []);

  function updateLayout() {
    setTimeout(() => {
      if (layout.current) {
        layout.current.updateLayout();
      }
    }, 20);
  }

  function leaveSession() {
    props.leaveHandler();
  }

  function toggleParticipants() {
    setShowParticipants(!showParticipants);
  }

  function toggleChat() {
    setShowChat(!showChat);
  }

  useEffect(() => {
    if (props.messageManager === null) return;

    function receiveMsg() {
      setChatCount((chatCount) => chatCount + 1);
    }
    if (showChat) {
      setChatCount(0);
    } else {
      props.messageManager.on('receive-msg', receiveMsg);
    }
    return () => {
      props.messageManager.off('receive-msg', receiveMsg);
    };
  }, [showChat, props.messageManager]);

  useEffect(() => {
    if (props.messageManager === null) return;

    function getUser(sender) {
      return props.otherUsers.find((ous) => ous.getStreamId() === sender);
    }

    function dataReceive(type, sender, msg) {
      if (onReceiveMessage) {
        onReceiveMessage({ sender: getUser(sender), type, msg });
      }
    }
    props.messageManager.on('data-receive', dataReceive);

    return () => {
      props.messageManager.off('data-receive', dataReceive);
    };
  }, [props.messageManager, onReceiveMessage, props.otherUsers]);

  useEffect(() => {
    if (props.messageManager === null) return;

    if (setMessageManager) {
      setMessageManager(props.messageManager);
    }
  }, [props.messageManager, setMessageManager]);

  return (
    <div className='container' id='container'>
      <div
        id='layout'
        className={classNames('bounds', {
          'show-participants': showParticipants,
          'show-chat': showChat
        })}
      >
        {props.localUser !== null && publisher && (
          <div
            className={classNames('OT_root OT_publisher', {
              OV_big: props.localUser.isScreenShareActive()
            })}
            id='localUser'
          >
            <StreamComponent user={props.localUser} loading={!publishing} />
          </div>
        )}
        {props.otherUsers.map((other) => {
          if (other.isPublishing()) {
            return (
              <div
                key={other.getStreamId()}
                className={classNames('OT_root OT_publisher', {
                  OV_big: other.isScreenShareActive()
                })}
                id='remoteUsers'
              >
                <StreamComponent user={other} />
              </div>
            );
          } else {
            return null;
          }
        })}
      </div>
      <div
        className={classNames('inf-side', {
          'show-participants': showParticipants,
          'show-chat': showChat
        })}
      >
        {showParticipants && (
          <ParticipantsList
            user={props.localUser}
            otherUsers={props.otherUsers}
            moderator={props.perfil === 'moderador'}
            messageManager={props.messageManager}
            updatePerfil={props.updatePerfil}
            lowerHand={props.lowerHand}
            sortParticipantsHandle={props.sortParticipantsHandle}
            customAction={props.customParticipantListAction}
            closeHandler={() => {
              setShowParticipants(false);
            }}
          />
        )}
        {props.localUser !== null && (
          <ChatComponent
            display={showChat}
            messageManager={props.messageManager}
            nickname={props.localUser.getNickname()}
            closeHandler={() => {
              setShowChat(false);
            }}
          />
        )}
      </div>
      <ToolbarComponent
        publisher={publisher}
        user={props.localUser}
        camStatusChanged={props.toggleLocalCam}
        micStatusChanged={props.toggleMuteLocalMic}
        haiseHandChanged={props.toggleHaiseHand}
        screenShare={props.screenShare}
        stopScreenShare={props.stopScreenShare}
        leaveSession={leaveSession}
        toggleChat={toggleChat}
        toggleParticipants={toggleParticipants}
        participantsCount={props.otherUsers.length + 1}
        chatCount={chatCount}
      >
        {props.children}
      </ToolbarComponent>
    </div>
  );
}

VideoRoom.propTypes = {
  token: PropTypes.string.isRequired,
  urlApi: PropTypes.string.isRequired,
  leaveHandler: PropTypes.func.isRequired,
  urlPlayer: PropTypes.string.isRequired,
  urlPublisher: PropTypes.string.isRequired,
  onReceiveMessage: PropTypes.func,
  setMessageManager: PropTypes.func,
  onSetUserProfile: PropTypes.func,
  onSetOtherUsers: PropTypes.func,
  sortParticipantsHandle: PropTypes.func,
  customParticipantListAction: PropTypes.func,
  onSetUser: PropTypes.func
};

export default WithWebRtc(VideoRoom);
