import React, { useCallback, useEffect, useState } from 'react'
import Navbar from 'react-bootstrap/Navbar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquare, faCheckSquare } from '@fortawesome/free-regular-svg-icons'
import ListGroup from 'react-bootstrap/ListGroup'
import PropTypes from 'prop-types'
import Loading from '../loading/Loading'
import Styles from './PerguntaItens.module.css'

function PerguntaItens({
  urlApiAssembleia,
  eventoId,
  userToken,
  pergunta,
  sohChecked,
  votos,
  titulo,
  onVoto,
  readOnly,
  className
}) {
  const [loading, setLoading] = useState(false)
  const [itens, setItens] = useState([])
  const [nRespostas, setNRespostas] = useState(0)
  const [itensNaoFiltrados, setItensNaoFiltrados] = useState([])

  const getItensDaPerguntaAtual = useCallback(() => {
    const its = votos.find((voto) => voto.perguntaId === pergunta.id)
    if (its === undefined) {
      return {}
    }
    return its
  }, [pergunta, votos])

  const calculaNumeroDeRespostas = useCallback(() => {
    const its = getItensDaPerguntaAtual().voto
    if (its === undefined) {
      setNRespostas(0)
      return
    }
    let n = 0
    its.forEach((el) => {
      n += el.valor
    })
    setNRespostas(n)
  }, [getItensDaPerguntaAtual])

  useEffect(() => {
    calculaNumeroDeRespostas()
  }, [calculaNumeroDeRespostas])

  const getItem = useCallback(
    (seq) => {
      const vts = getItensDaPerguntaAtual()
      if (Object.keys(vts).length === 0) {
        return { valor: 0 }
      }
      return vts.voto.find((item) => item.seq === seq)
    },
    [getItensDaPerguntaAtual]
  )

  const filtraChecked = useCallback(
    (its) => its.filter((item) => getItem(item.seq).valor === 1),
    [getItem]
  )

  useEffect(() => {
    const fetchItens = async () => {
      setLoading(true)

      const itensReturn = await fetch(
        `${urlApiAssembleia}/eventos/${eventoId}/perguntas/${pergunta.id}/itens`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${userToken}`
          }
        }
      )

      setLoading(false)
      if (itensReturn.status === 200) {
        setItensNaoFiltrados(await itensReturn.json())
        return
      }

      const resposta = await itensReturn.json()
      console.error(resposta)
      if (resposta.mensagem) {
        alert(resposta.mensagem)
      } else {
        alert('Ops.. Erro ao recuperar os itens, tente novamente mais tarde')
      }
    }

    fetchItens()
  }, [eventoId, pergunta, userToken, urlApiAssembleia])

  useEffect(() => {
    if (sohChecked) {
      setItens(filtraChecked(itensNaoFiltrados))
    } else {
      setItens(itensNaoFiltrados)
    }
  }, [filtraChecked, sohChecked, itensNaoFiltrados])

  const handleItemClick = (item) => {
    onVoto(pergunta, itens, item)
  }

  if (loading || (itens.length > 0 && itens[0].perguntaId !== pergunta.id)) {
    return <Loading />
  }

  const listItens = itens.map((item) => (
    <ItemListItem
      onClick={handleItemClick}
      item={item}
      key={item.seq}
      disabled={
        readOnly ||
        (getItem(item.seq).valor !== 1 &&
          nRespostas >= pergunta.nMaxRespostas &&
          pergunta.nMaxRespostas > 1)
      }
      checked={getItem(item.seq).valor === 1}
    />
  ))

  return (
    <div className={className}>
      {titulo && (
        <Navbar bg="light" className="py-1 navbar-2">
          <Navbar.Brand className={Styles.cursorDefault}>{titulo}</Navbar.Brand>
        </Navbar>
      )}
      <ListGroup>{listItens}</ListGroup>
    </div>
  )
}

PerguntaItens.propTypes = {
  urlApiAssembleia: PropTypes.string.isRequired,
  eventoId: PropTypes.number.isRequired,
  userToken: PropTypes.string.isRequired,
  pergunta: PropTypes.shape({
    id: PropTypes.number.isRequired,
    nMaxRespostas: PropTypes.number.isRequired
  }).isRequired,
  sohChecked: PropTypes.bool,
  votos: PropTypes.arrayOf(
    PropTypes.shape({ perguntaId: PropTypes.number.isRequired })
  ).isRequired,
  titulo: PropTypes.string,
  onVoto: PropTypes.func,
  readOnly: PropTypes.bool,
  className: PropTypes.string
}

PerguntaItens.defaultProps = {
  sohChecked: false,
  titulo: 'OPÇÕES',
  onVoto: () => {},
  readOnly: false,
  className: null
}

function ItemListItem({ disabled, item, onClick, checked }) {
  return (
    <ListGroup.Item
      disabled={disabled}
      onClick={() => onClick(item)}
      className="d-flex p-0 rounded-0 align-items-stretch"
      action
    >
      <div className="border-right border-end p-3">
        <FontAwesomeIcon
          className={checked ? 'text-primary' : 'text-muted'}
          size="2x"
          icon={checked ? faCheckSquare : faSquare}
        />
      </div>
      <div className="font-weight-bold p-3">{item.descricao}</div>
    </ListGroup.Item>
  )
}

ItemListItem.propTypes = {
  disabled: PropTypes.bool.isRequired,
  item: PropTypes.shape({
    descricao: PropTypes.string.isRequired
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired
}

export default PerguntaItens
