import React, { useCallback, useEffect, useRef, useState } from 'react';
import Fab from '@material-ui/core/Fab';
import Send from '@material-ui/icons/Send';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Toolbar from '@material-ui/core/Toolbar';
import './ChatComponent.css';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { save } from 'save-file';
import md5 from 'md5';

export default function ChatComponent(props) {
  const [messageList, setMessageList] = useState([]);
  const [message, setMessage] = useState('');
  const chatScroll = useRef(null);
  const input = React.createRef();

  const receiveMsg = useCallback(
    (sender, data) => {
      const hash = md5(JSON.stringify(data));
      if (messageList.find((m) => m.hash === hash)) {
        return;
      }
      data.hash = hash;
      messageList.push(data);
      setMessageList([...messageList]);
    },
    [messageList]
  );

  useEffect(() => {
    props.messageManager.on('receive-msg', receiveMsg);
    return () => {
      props.messageManager.off('receive-msg', receiveMsg);
    };
  }, [props.messageManager, receiveMsg]);

  function handleChange(event) {
    setMessage(event.target.value);
  }

  function handlePressKey(event) {
    if (event.key === 'Enter') {
      sendMessage();
    }
  }

  function sendMessage() {
    if (message) {
      if (message.trim() !== '') {
        const data = {
          message: message,
          nickname: props.nickname
        };
        props.messageManager.sendMessage(data);
        data.local = true;
        messageList.push(data);
        setMessageList([...messageList]);
      }
    }
    setMessage('');
    input.current.focus();
  }

  useEffect(() => {
    if (chatScroll.current !== null)
      chatScroll.current.scrollIntoView({ behavior: 'smooth' });
  }, [messageList]);

  function close() {
    props.closeHandler();
  }

  function formatChatToSave() {
    return messageList.map((ml) => ml.nickname + ': ' + ml.message).join('\n');
  }
  function getFilename() {
    const now = new Date();
    return now.toLocaleString().replace(' ', '_') + '.txt';
  }

  function saveChat() {
    save(new Blob([formatChatToSave()]), getFilename());
  }

  if (props.display === false) {
    return null;
  }

  return (
    <div className='chatComponent'>
      <AppBar position='relative' color='secondary'>
        <Toolbar variant='dense'>
          Bate Papo
          <IconButton
            onClick={saveChat}
            className='save-button'
            color='inherit'
          >
            <SaveAltIcon />
          </IconButton>
          {props.closeHandler && (
            <IconButton
              onClick={close}
              className='close-button'
              color='inherit'
            >
              <CancelIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      <div className='message-wrap'>
        {messageList.map((data, i) => (
          <div
            key={i}
            id='remoteUsers'
            className={'message' + (data.local ? ' left' : ' right')}
          >
            <Avatar className='msg-avatar'>{data.nickname.charAt(0)}</Avatar>
            <div className='msg-detail'>
              <div className='msg-info'>
                <p>{data.nickname}</p>
              </div>
              <div className='msg-content'>
                <span className='triangle' />
                <p className='text'>{data.message}</p>
              </div>
            </div>
          </div>
        ))}
        <div style={{ float: 'left', clear: 'both' }} ref={chatScroll} />
      </div>

      <div id='messageInput'>
        <input
          autoComplete='off'
          ref={input}
          placeholder='Enviar mensagem'
          id='chatInput'
          value={message}
          onChange={handleChange}
          onKeyPress={handlePressKey}
        />
        <Fab size='small' id='sendButton' color='primary' onClick={sendMessage}>
          <Send />
        </Fab>
      </div>
    </div>
  );
}
