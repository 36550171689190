const ACTIONS = {
  OPEN_POLL: 'open-poll',
  CLOSE_POLL: 'close-poll',
  CLEAN_RESULTS: 'clean-results',
  UPDATE_RESULTS: 'update-results',
  REGISTER_VOTE: 'register-vote',
  SET_POLL_OWNER: 'poll-owner'
}

export default ACTIONS
