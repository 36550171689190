import React from 'react'
import PropTypes from 'prop-types'

import MuiDialogTitle from '@material-ui/core/DialogTitle'
import { IconButton, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

const DialogTitle = ({ children, onClose }) => {
  return (
    <MuiDialogTitle
      id='dialog-title'
      disableTypography
      style={{ cursor: 'move' }}
    >
      <Typography className='title' variant='h6'>
        {children}
      </Typography>
      {onClose && (
        <IconButton className='closeModal' onClick={onClose}>
          <CloseIcon />
        </IconButton>
      )}
    </MuiDialogTitle>
  )
}

DialogTitle.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func
}

export default DialogTitle
