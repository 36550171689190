import React from 'react'
import Container from 'react-bootstrap/Container'
import Spinner from 'react-bootstrap/Spinner'

function Loading() {
  return (
    <Container className="d-flex justify-content-center" data-testid="loading">
      <Spinner animation="border" role="status">
        <span className="sr-only visually-hidden">Carregando...</span>
      </Spinner>
    </Container>
  )
}

export default Loading
