import React, { createContext, useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { TYPE_QUESTAO_ORDEM } from './messages'
import UserQuestaoOrdem from './user-questao-ordem'

const QuestaoDeOrdemContext = createContext({
  users: [],
  setUser: () => {}
})

function ProviderQuestaoDeOrdem({ onSetUsers, children, messageManager }) {
  const [users, setUsers] = useState([])

  const setUser = useCallback(
    ({ streamId, value }) => {
      const user = users.find((u) => u.streamId === streamId)
      if (user) {
        if (user.raised === value.raised && user.dataHora === value.dataHora)
          return
        user.raised = value.raised
        user.dataHora = value.dataHora
      } else {
        users.push(
          new UserQuestaoOrdem({
            streamId,
            raised: value.raised,
            dataHora: value.dataHora
          })
        )
      }
      setUsers(users)
      if (onSetUsers) onSetUsers([...users])
    },
    [onSetUsers, users]
  )

  useEffect(() => {
    if (messageManager === null) return

    function onReceiveMessage(type, sender, msg) {
      if (type !== TYPE_QUESTAO_ORDEM) return
      setUser({ streamId: sender, value: msg })
    }
    messageManager.on('data-receive', onReceiveMessage)
    return () => {
      messageManager.off('data-receive', onReceiveMessage)
    }
  }, [messageManager, setUser])

  return (
    <QuestaoDeOrdemContext.Provider value={{ users, setUser }}>
      {children}
    </QuestaoDeOrdemContext.Provider>
  )
}

ProviderQuestaoDeOrdem.propTypes = {
  messageManager: PropTypes.object,
  onSetUsers: PropTypes.func,
  children: PropTypes.node
}

export { QuestaoDeOrdemContext }
export default ProviderQuestaoDeOrdem
