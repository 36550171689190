import assign from 'lodash/assign';

class UserModel {
  stream;
  audioActive;
  videoActive;
  screenShareActive;
  nickname;
  streamId;
  type; // 'remote' | 'local'
  publishing;
  pingDate;
  slowInternetMode;
  nSlowPing;
  nFastPing;

  constructor(data) {
    this.stream = null;
    this.audioActive = true;
    this.videoActive = true;
    this.screenShareActive = false;
    this.nickname = '...';
    this.streamId = null;
    this.type = 'local';
    this.publishing = false;
    this.pingDate = new Date();
    this.haiseHand = false;
    this.haiseHandDataHora = null;
    this.slowInternetMode = false;
    this.nSlowPing = 0;
    this.nFastPing = 0;

    assign(this, data);
  }

  fromObject(data) {
    this.videoActive = data.videoActive;
    this.audioActive = data.audioActive;
    this.screenShareActive = data.screenShareActive;
    this.nickname = data.nickname;
    this.haiseHand = data.haiseHand;
    this.haiseHandDataHora = data.haiseHandDataHora;
  }

  ping() {
    this.pingDate = new Date();
  }

  getPingDate() {
    return this.pingDate;
  }

  setPublishing(publishing) {
    this.publishing = publishing;
  }

  isPublishing() {
    return this.publishing;
  }

  isAudioActive() {
    return this.audioActive;
  }

  isVideoActive() {
    return this.videoActive;
  }

  isScreenShareActive() {
    return this.screenShareActive;
  }

  getStream() {
    return this.stream;
  }

  getNickname() {
    return this.nickname;
  }

  getStreamId() {
    return this.streamId;
  }

  isLocal() {
    return this.type === 'local';
  }

  isRemote() {
    return !this.isLocal();
  }

  setAudioActive(isAudioActive) {
    this.audioActive = isAudioActive;
  }

  setVideoActive(isVideoActive) {
    this.videoActive = isVideoActive;
  }

  setScreenShareActive(isScreenShareActive) {
    this.screenShareActive = isScreenShareActive;
  }

  setStreamId(streamId) {
    this.streamId = streamId;
  }

  setStream(stream) {
    this.stream = stream;
  }

  getStreamStartId() {
    return this.streamStartId;
  }

  setNickname(nickname) {
    this.nickname = nickname;
  }

  setHaiseHand(bool) {
    this.haiseHand = bool;
    this.haiseHandDataHora = new Date().getTime();
  }

  getHaiseHand() {
    return this.haiseHand;
  }

  getHaiseHandDataHora() {
    return this.haiseHandDataHora;
  }

  setType(type) {
    if ((type === 'local') | (type === 'remote')) {
      this.type = type;
    }
  }

  setSlowInternetMode(enabled) {
    this.slowInternetMode = enabled;
  }

  isSlowInternetMode() {
    return this.slowInternetMode;
  }

  getNSlowPing() {
    return this.nSlowPing;
  }

  getNFastPing() {
    return this.nFastPing;
  }

  slowPing() {
    if (this.nSlowPing < 10000) {
      this.nSlowPing++;
    }
    this.nFastPing = 0;
  }

  fastPing() {
    if (this.nFastPing < 10000) {
      this.nFastPing++;
    }
    this.nSlowPing = 0;
  }
}

export default UserModel;
