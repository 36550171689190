/* global fetch */

import WebRTCAdaptor from './webrtc-adaptor';

class WebrtcPlayer {
  constructor({
    urlApi,
    url,
    tokenApi,
    roomId,
    streamId,
    messageManager,
    bitrateMeasurementCallback,
    updateActiveStreamsCallback,
    startStreamCallback,
    stopStreamCallback
  }) {
    this.url = url;
    this.urlApi = urlApi;
    this.tokenApi = tokenApi;
    this.tokenPlay = null;
    this.roomId = roomId;
    this.streamId = streamId;
    this.webRTCPlayer = null;
    this.initing = false;
    this.replaying = false;
    this.lastPlay = null;

    this.timerRoominfo = null;

    this.updateActiveStreamsCallback = updateActiveStreamsCallback;
    this.startStreamCallback = startStreamCallback;
    this.stopStreamCallback = stopStreamCallback;
    this.messageManager = messageManager;
    this.bitrateMeasurementCallback = bitrateMeasurementCallback;
  }

  init = async () => {
    if (this.initing) return;
    this.initing = true;
    console.info('iniciando player');
    try {
      await this.refreshTokenPlay();
      this.updateActiveStreamsCallback({ streams: [] });

      this.webRTCPlayer = new WebRTCAdaptor(this.url, true, {
        initializedCallback: this.initializePlayer,
        joinedTheRoomCallback: this.joinedTheRoomPlayer,
        newStreamAvailableCallback: this.newStreamAvaliablePlayer,
        roomInformationCallback: this.roomInformationPlayer,
        dataReceivedCallback: this.messageManager.onDataReceive,
        bitrateMeasurementCallback: this.bitrateMeasurementCallback,
        playFinishedCallback: this.playFinished,
        errorGenericCallback: this.errorPlayer
      });

      if (this.timerRoominfo === null) {
        this.timerRoominfo = setInterval(() => {
          this.webRTCPlayer.getRoomInfo(this.roomId, this.streamId);
        }, 1000);
      }
    } catch (e) {
      console.warn(e);
      this.initing = false;
    }
  };

  refreshTokenPlay = async () => {
    const fet = await fetch(this.urlApi + '/token-play', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.tokenApi
      }
    });
    if (fet.status !== 200) {
      console.error(await fet.text());
      return;
    }
    this.tokenPlay = (await fet.json()).token;
  };

  initializePlayer = () => {
    this.initing = false;
    this.webRTCPlayer.joinRoom(this.roomId, this.streamId);
  };

  joinedTheRoomPlayer = ({ streams }) => {
    if (!Array.isArray(streams)) return;
    this.updateActiveStreamsCallback({ streams });
  };

  newStreamAvaliablePlayer = ({ stream, streamId }) => {
    this.startStreamCallback({ stream, streamId });
  };

  playFinished = ({ streamId }) => {
    this.stopStreamCallback({ streamId });
  };

  roomInformationPlayer = ({ streams }) => {
    this.checkAndReplayIfRequired(streams);
    this.updateActiveStreamsCallback({ streams });
  };

  errorPlayer = async (error, msg) => {
    this.initing = false;
    console.warn('errorPlayer');
    console.log(error, msg);
    switch (error) {
      case 'already_playing':
        if (this.lastPlay) {
          this.stop(this.lastPlay);
        }
        break;
      default:
        break;
    }
  };

  stop = (streamId) => {
    this.webRTCPlayer.stop(streamId);
  };

  sendData = (streamId, message) => {
    this.webRTCPlayer.sendData(streamId, message);
  };

  tryPlay = (streamId, roomId) => {
    this.lastPlay = streamId;
    this.webRTCPlayer.tryPlay(streamId, this.tokenPlay, roomId);
  };

  checkAndReplayIfRequired = async (streams) => {
    if (!Array.isArray(streams)) return;
    if (this.replaying) return;
    this.replaying = true;
    streams.forEach((streamId) => {
      var iceState = this.webRTCPlayer.iceConnectionState(streamId);
      console.info('stream play state', streamId, iceState);
      if (
        iceState === null ||
        iceState === 'failed' ||
        iceState === 'disconnected'
      ) {
        try {
          this.stopStreamCallback({ streamId });
          this.webRTCPlayer.closePeerConnection(streamId);
          this.webRTCPlayer.tryPlay(streamId, this.tokenPlay, this.roomId);
        } catch (e) {
          console.warn(e);
        }
      }
    });
    this.replaying = false;
  };

  kill = () => {
    clearInterval(this.timerRoominfo);
    try {
      this.webRTCPlayer.leaveFromRoom(this.roomId);
    } catch (e) {
      console.warn(e);
    }
    this.webRTCPlayer.kill(this.streamId, true);
  };

  toggleVideo = (streamId, trackId, enabled) => {
    this.webRTCPlayer.toggleVideo(streamId, trackId, enabled);
  };
}

export default WebrtcPlayer;
