import React, { useCallback, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'

import { IconButton } from '@material-ui/core'

import PollIcon from '../PollIcon/PollIcon'
import ModalVote from './ModalVote/ModalVote'
import ModalResults from './ModalResults/ModalResults'
import { reducer as pollReducer, ACTIONS } from '../../reducers/poll'

import './Poll.scss'
import usePrevious from '../../hooks/usePrevious'

const Poll = ({
  onVote,
  onPollStatusChange,
  isAllowedToOpenPoll,
  totalUsers,
  userId,
  eventEmitter,
  eventName
}) => {
  const [state, dispatch] = useReducer(pollReducer, {
    open: false,
    pollVotesList: new Map([]),
    results: new Map([]),
    totalUsers: 0,
    pollOwner: null
  })
  const previousState = usePrevious(state)
  const canSendPollStatus =
    isAllowedToOpenPoll &&
    userId &&
    (state.pollOwner === null || state.pollOwner === userId)

  const votesPercentage = () =>
    Math.round((state.pollVotesList.size / totalUsers) * 100)

  const onVoteClick = (newVote) => {
    dispatch({
      type: ACTIONS.REGISTER_VOTE,
      vote: newVote,
      id: userId
    })

    onVote({ vote: newVote })
  }

  const onCloseResultsClick = () => {
    dispatch({ type: ACTIONS.CLEAN_RESULTS })
  }

  const onPollStatusClick = () => {
    if (!state.open) {
      dispatch({ type: ACTIONS.OPEN_POLL })
      dispatch({ type: ACTIONS.CLEAN_RESULTS })
    }

    if (state.open) {
      dispatch({ type: ACTIONS.CLOSE_POLL })
      dispatch({
        type: ACTIONS.UPDATE_RESULTS,
        totalUsers: totalUsers
      })
      stopSendingVotes()
    }

    dispatch({ type: ACTIONS.SET_POLL_OWNER, value: userId })
  }

  const stopSendingVotes = useCallback(() => {
    onVote(null)
  }, [onVote])

  const onReceiveData = useCallback(
    (type, sender, msg) => {
      if (type === 'TYPE-VOTE') {
        dispatch({
          type: ACTIONS.REGISTER_VOTE,
          vote: msg.vote,
          id: sender
        })
        return
      }

      if (type === 'TYPE-OPEN-POLL') {
        dispatch({ type: ACTIONS.SET_POLL_OWNER, value: sender })

        if (msg.open) {
          dispatch({ type: ACTIONS.OPEN_POLL })
          dispatch({ type: ACTIONS.CLEAN_RESULTS })
        }

        if (!msg.open) {
          dispatch({
            type: ACTIONS.CLOSE_POLL
          })
          dispatch({
            type: ACTIONS.UPDATE_RESULTS,
            totalUsers: msg.totalUsers,
            results: new Map(JSON.parse(msg.results))
          })
          stopSendingVotes()
        }
      }
    },
    [stopSendingVotes]
  )

  useEffect(() => {
    if (!eventEmitter || !eventName) return

    eventEmitter.on(eventName, onReceiveData)

    return () => {
      eventEmitter.off(eventName, onReceiveData)
    }
  }, [eventName, eventEmitter, onReceiveData])

  useEffect(() => {
    if (previousState && state !== previousState && canSendPollStatus) {
      onPollStatusChange({
        open: state.open,
        results: state.results,
        totalUsers: state.totalUsers
      })
    }
  }, [state, previousState, onPollStatusChange, canSendPollStatus])

  return (
    <div>
      {canSendPollStatus && (
        <>
          <IconButton
            className={`changePollStatusButton ${state.open ? 'open' : ''}`}
            color='inherit'
            size='small'
            title={`${
              state.open
                ? 'Parar Votação e Mostrar Resultados'
                : 'Iniciar Votação'
            }`}
            onClick={onPollStatusClick}
          >
            <PollIcon />
          </IconButton>
          {state.open && `${votesPercentage()}%`}
        </>
      )}

      <ModalVote
        isOpen={!!state.open && !state.pollVotesList.get(userId)}
        onVote={onVoteClick}
      />
      <ModalResults
        pollVotesList={state.results}
        isOpen={!state.open && state.results.size > 0}
        onClose={onCloseResultsClick}
        totalUsersOnlineDuringPoll={state.totalUsers}
      />
    </div>
  )
}

Poll.propTypes = {
  onVote: PropTypes.func.isRequired,
  totalUsers: PropTypes.number.isRequired,
  onPollStatusChange: PropTypes.func.isRequired,
  isAllowedToOpenPoll: PropTypes.bool.isRequired,
  userId: PropTypes.any,
  eventEmitter: PropTypes.any,
  eventName: PropTypes.string
}

export default Poll
