import React, { useState } from 'react';
import './StreamComponent.css';
import OvVideoComponent from './OvVideo';

import MicOff from '@material-ui/icons/MicOff';
import VideocamOff from '@material-ui/icons/VideocamOff';
import VolumeUp from '@material-ui/icons/VolumeUp';
import VolumeOff from '@material-ui/icons/VolumeOff';
import IconButton from '@material-ui/core/IconButton';
import PanToolIcon from '@material-ui/icons/PanTool';

export default function StreamComponent(props) {
  const [mutedSound, setMutedSound] = useState(false);

  function toggleSound() {
    setMutedSound(!mutedSound);
  }

  return (
    <div className='OT_widget-container'>
      {props.user !== undefined &&
      (props.user.isLocal() || props.user.getStream() !== null) ? (
        <>
          <div className='nickname'>
            <div>
              <span id='nickname'>{props.user.getNickname()}</span>
            </div>
          </div>
          <div className='streamComponent'>
            <OvVideoComponent
              user={props.user}
              mutedSound={mutedSound}
              loading={props.loading}
            />
            <div id='statusIcons'>
              {!props.user.isVideoActive() ? (
                <div id='camIcon' className='statusIcon'>
                  <VideocamOff id='statusCam' />
                </div>
              ) : null}

              {!props.user.isAudioActive() ? (
                <div id='micIcon' className='statusIcon'>
                  <MicOff id='statusMic' />
                </div>
              ) : null}
              {props.user.getHaiseHand() ? (
                <div id='handIcon' className='statusIcon'>
                  <PanToolIcon />
                </div>
              ) : null}
            </div>
            <div>
              {!props.user.isLocal() && (
                <IconButton id='volumeButton' onClick={toggleSound}>
                  {mutedSound ? <VolumeOff color='secondary' /> : <VolumeUp />}
                </IconButton>
              )}
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}
