import React from 'react'
import PropTypes from 'prop-types'

import { IconButton } from '@material-ui/core'
import { Timer as TimerIcon } from '@material-ui/icons'

import './InitTimerButton.scss'

const InitTimerButton = ({ onClick, timerStatus }) => {
  return (
    <IconButton
      data-testid='timer-status-button'
      className={`changeTimerStatusButton ${timerStatus ? 'initiated' : ''}`}
      color='inherit'
      size='small'
      title={`${
        timerStatus ? 'Parar Contagem de Tempo' : 'Iniciar Contagem de Tempo'
      }`}
      onClick={onClick}
    >
      <TimerIcon />
    </IconButton>
  )
}

InitTimerButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  timerStatus: PropTypes.bool.isRequired
}

export default InitTimerButton
