import React, { useCallback, useContext, useEffect, useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import Flag from '@material-ui/icons/Flag'
import Icon from '@material-ui/core/Icon'
import PropTypes from 'prop-types'
import { sendLower } from './messages'
import { QuestaoDeOrdemContext } from './ProviderQuestaoDeOrdem'

function ParticipantAction({
  user,
  messageManager,
  usersQuestaoOrdem,
  userProfile
}) {
  const [raised, setRaised] = useState(false)
  const [loadingQuestaoOrdem, setLoadingQuestaoOrdem] = useState(false)
  const { setUser } = useContext(QuestaoDeOrdemContext)

  useEffect(() => {
    const userQuestaoOrdem = usersQuestaoOrdem.find(
      (u) => u.streamId === user.getStreamId()
    )
    if (userQuestaoOrdem) setRaised(userQuestaoOrdem.raised)
  }, [user, usersQuestaoOrdem])

  useEffect(() => {
    setLoadingQuestaoOrdem(false)
  }, [user])

  const onClickLowerHand = useCallback(() => {
    setLoadingQuestaoOrdem(true)
    if (user.isLocal()) {
      setUser({
        streamId: user.getStreamId(),
        value: {
          raised: false
        }
      })
    }
    sendLower({
      messageManager,
      streamId: user.getStreamId()
    })
  }, [user, messageManager, setUser])

  if (loadingQuestaoOrdem) {
    return <CircularProgress size={24} />
  }

  if (raised) {
    if (userProfile === 'moderador') {
      return (
        <IconButton onClick={onClickLowerHand} color='primary'>
          <Flag />
        </IconButton>
      )
    } else {
      return (
        <Icon color='primary'>
          <Flag />
        </Icon>
      )
    }
  }

  return null
}

ParticipantAction.propTypes = {
  messageManager: PropTypes.object,
  user: PropTypes.object,
  userProfile: PropTypes.string,
  usersQuestaoOrdem: PropTypes.array
}

export default ParticipantAction
