import React from 'react';

import ChatComponent from './ChatComponent';
import './ChatInside.css';

export default function ChatInside({ display, nickname, messageManager }) {
  return (
    <div className='chatInside'>
      <ChatComponent
        display={display}
        messageManager={messageManager}
        nickname={nickname}
      />
    </div>
  );
}
