import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Slider, Button, TextField } from '@material-ui/core'
import { Timer as TimerIcon } from '@material-ui/icons'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

import usePrevious from '../../../hooks/usePrevious'
import Dialog from '../../Dialog/Dialog'
import './ModalTimeChoice.scss'
import {
  DEFAULT_TIMER_COUNTDOWN,
  ONE_SECOND_TIME
} from '../../../shared/constants/constants'

dayjs.extend(duration)

export const TimeMask = (props) => {
  const { ...other } = props
  delete other.inputRef

  return <TextField {...other} />
}

const maxPredefinedTime = 5 * 60 * ONE_SECOND_TIME

const ModalTimeChoice = ({ onClose, onChoose, isOpen }) => {
  const [time, setTime] = useState(DEFAULT_TIMER_COUNTDOWN)
  const [customTime, setCustomTime] = useState('')
  const [customTimeAsSeconds, setCustomTimeAsSeconds] = useState(null)
  const previousTime = usePrevious(time)

  const marks = [
    {
      value: 60 * ONE_SECOND_TIME,
      label: '1min'
    },
    {
      value: 2 * 60 * ONE_SECOND_TIME,
      label: '2min'
    },
    {
      value: 3 * 60 * ONE_SECOND_TIME,
      label: '3min'
    },
    {
      value: 4 * 60 * ONE_SECOND_TIME,
      label: '4min'
    },
    {
      value: maxPredefinedTime,
      label: '5min'
    }
  ]

  const isCustomTimeValid = () => {
    if (customTime === '') {
      return true
    }

    return customTime.match(/([0-9][0-9]):([0-9][0-9])/g)
  }

  const onCustomTimeChange = ({ target: { value } }) => {
    setCustomTime(value)
    if (!value) {
      setCustomTimeAsSeconds(null)
      return
    }

    if (!value.match(/([0-9][0-9]):([0-9][0-9])/g)) {
      return
    }

    const timeDuration = dayjs
      .duration({
        minutes: value.split(':')[0],
        seconds: value.split(':')[1]
      })
      .as('milliseconds')

    setCustomTimeAsSeconds(timeDuration)
  }

  return (
    <Dialog
      title='Escolha o tempo de fala'
      isOpen={isOpen}
      onClose={onClose}
      testId='modal-time-choice'
    >
      <div className='modalTimeChoiceContent'>
        <div className='sliderWrapper'>
          <Slider
            value={time ?? previousTime}
            step={60 * ONE_SECOND_TIME}
            marks={marks}
            min={60 * ONE_SECOND_TIME}
            max={maxPredefinedTime}
            onChange={(event, newValue) => {
              setTime(newValue)
              setCustomTime('')
              setCustomTimeAsSeconds(null)
            }}
            valueLabelDisplay='off'
          />
          <TextField
            type='text'
            variant='outlined'
            margin='dense'
            error={!isCustomTimeValid()}
            helperText={!isCustomTimeValid() && 'Valor inválido'}
            InputLabelProps={{
              shrink: true
            }}
            value={customTime}
            onChange={onCustomTimeChange}
            InputProps={{
              inputComponent: TimeMask
            }}
          />
        </div>
        <Button
          data-testid='init-timer-button'
          variant='contained'
          color='default'
          onClick={() => onChoose(customTimeAsSeconds ?? time)}
          startIcon={<TimerIcon />}
        >
          Iniciar Contagem
        </Button>
      </div>
    </Dialog>
  )
}

ModalTimeChoice.propTypes = {
  onChoose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
}

export default ModalTimeChoice
