import EventEmitter from 'wolfy87-eventemitter';

export default class MessageManager extends EventEmitter {
  constructor(streamId) {
    super();
    this.streamId = streamId;
  }

  onDataReceive = ({ type, sender, value }) => {
    if (sender === this.streamId) return;

    switch (type) {
      case 'SET_ME':
        this.emitEvent('user-info', [sender, value]);
        break;
      case 'SEND_MSG':
        this.emitEvent('receive-msg', [sender, value]);
        break;
      case 'UPDATE_PERFIL':
        this.emitEvent('receive-update-perfil', [sender, value]);
        break;
      case 'BYE':
        this.emitEvent('bye', [sender]);
        break;
      case 'LOWER_HAND':
        this.emitEvent('receive-lower-hand', [sender, value]);
        break;
      default:
    }
    this.emitEvent('data-receive', [type, sender, value]);
  };

  sendMessage({ nickname, message }) {
    const data = {
      message: message,
      nickname: nickname,
      timestamp: new Date().getTime
    };

    this.emitEvent('send-msg', [data]);
  }

  sendCustomData(type, data) {
    this.emitEvent('send-custom-data', [type, data]);
  }
}
