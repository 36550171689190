/* global AudioContext */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

function MicTest(props) {
  const volume = useRef(0);
  const canvas = useRef();
  const averaging = 0.95;

  useEffect(() => {
    let canvasCtx = null;
    let stream = null;
    navigator.mediaDevices
      .getUserMedia({
        audio: true
      })
      .then((streamAudio) => {
        stream = streamAudio;
        var audioCtx = new AudioContext();
        var source = audioCtx.createMediaStreamSource(stream);
        var processor = audioCtx.createScriptProcessor(256, 1, 1);

        canvasCtx = canvas.current.getContext('2d');
        canvasCtx.fillStyle = props.color;

        processor.onaudioprocess = process;
        processor.connect(audioCtx.destination);
        source.connect(processor);
      })
      .catch(function (err) {
        console.error(
          'Error occurred while initalizing audio input: ' + err.toString()
        );
      });

    function process(event) {
      const buf = event.inputBuffer.getChannelData(0);
      let sum = 0;
      let x;

      for (var i = 0; i < buf.length; i++) {
        x = buf[i];
        sum += x * x;
      }

      const rms = Math.sqrt(sum / buf.length);
      volume.current = Math.max(rms, volume.current * averaging);

      canvasCtx.clearRect(
        0,
        0,
        canvasCtx.canvas.width,
        canvasCtx.canvas.height
      );
      canvasCtx.fillRect(
        0,
        0,
        canvasCtx.canvas.width * volume.current,
        canvasCtx.canvas.height
      );
    }
    return () => {
      if (stream !== null) {
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [props.color]);

  return <canvas ref={canvas} width={props.width} height={props.height} />;
}

MicTest.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
};

MicTest.defaultProps = {
  width: 150,
  height: 25,
  color: '#00FF48'
};

export default MicTest;
