export const TYPE_QUESTAO_ORDEM = 'TYPE-QUESTAO-ORDEM'

export const TYPE_QUESTAO_ORDEM_LOWER = 'TYPE-QUESTAO-ORDEM-LOWER'

export function sendRaise({ messageManager, raised, dataHora }) {
  messageManager.sendCustomData(TYPE_QUESTAO_ORDEM, { raised, dataHora })
}

export function sendLower({ messageManager, streamId }) {
  messageManager.sendCustomData(TYPE_QUESTAO_ORDEM_LOWER, {
    streamId
  })
}
