import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Permission } from '@panagora/cam-mic-test-screen';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@panagora/cam-mic-test-screen/dist/index.css';
import ChatInside from '../chat/ChatInside';

function DenyRender({ messageManager, localUser }) {
  return (
    <div className='mb-3'>
      <p>
        Você precisa autorizar a câmera e o microfone para continuar, para isso
        clique no icône que fica próximo a barra de endereço e autorize.
        <br />
        Peça ajuda aos integrantes no bate papo a baixo
      </p>
      {localUser && (
        <ChatInside
          display
          messageManager={messageManager}
          nickname={localUser.getNickname()}
        />
      )}
    </div>
  );
}

export default function NotAllowedDialog({
  open,
  handleClose,
  messageManager,
  localUser
}) {
  function onPermissionChanged(permission) {
    if (permission) {
      handleClose();
    }
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth='xs'
    >
      {open && (
        <Permission
          onPermissionChanged={onPermissionChanged}
          denyRender={
            <DenyRender messageManager={messageManager} localUser={localUser} />
          }
        />
      )}
    </Dialog>
  );
}
