import React, { useContext, useEffect, useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Flag from '@material-ui/icons/Flag'
import PropTypes from 'prop-types'
import { QuestaoDeOrdemContext } from './ProviderQuestaoDeOrdem'
import { sendRaise, TYPE_QUESTAO_ORDEM_LOWER } from './messages'
import useFindQuestaoDeOrdem from './useFindQuestaoDeOrdem'

function QuestaoDeOrdemToolbar({ messageManager, user }) {
  const [raised, setRaised] = useState({ bool: false, dataHora: null })
  const { setUser } = useContext(QuestaoDeOrdemContext)
  const myQuestaoDeOrdemUser = useFindQuestaoDeOrdem(user?.getStreamId())
  const myRaised = myQuestaoDeOrdemUser?.raised

  useEffect(() => {
    if (myRaised === undefined) return
    setRaised({ bool: myRaised })
  }, [myRaised])

  useEffect(() => {
    if (messageManager === null) return
    const timer = setInterval(() => {
      sendRaise({
        messageManager,
        raised: raised.bool,
        dataHora: raised.dataHora
      })
    }, 5000)
    sendRaise({
      messageManager,
      raised: raised.bool,
      dataHora: raised.dataHora
    })

    return () => {
      clearInterval(timer)
    }
  }, [messageManager, raised])

  useEffect(() => {
    if (messageManager === null) return

    function onReceiveMessage(type, sender, msg) {
      if (type !== TYPE_QUESTAO_ORDEM_LOWER) return
      if (msg.streamId !== user.getStreamId()) return
      setRaised({ bool: false, dataHora: null })
      setUser({
        streamId: user.getStreamId(),
        value: {
          raised: false,
          dataHora: null
        }
      })
    }
    messageManager.on('data-receive', onReceiveMessage)
    return () => {
      messageManager.off('data-receive', onReceiveMessage)
    }
  }, [messageManager, setUser, user])

  const toggleQuestaoOrdem = () => {
    const newRaised = !raised.bool
    const dataHora = new Date().getTime()
    setRaised({ bool: newRaised, dataHora })
    setUser({
      streamId: user.getStreamId(),
      value: {
        raised: newRaised,
        dataHora: dataHora
      }
    })
  }

  return (
    <IconButton
      className='navButton'
      color='inherit'
      onClick={toggleQuestaoOrdem}
    >
      <Tooltip title='Questão de Ordem'>
        {raised.bool ? <Flag color='secondary' /> : <Flag />}
      </Tooltip>
    </IconButton>
  )
}

QuestaoDeOrdemToolbar.propTypes = {
  messageManager: PropTypes.object,
  user: PropTypes.object
}

export default QuestaoDeOrdemToolbar
