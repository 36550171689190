export default function sortParticipantsHandle(usersGeral, usersQuestaoOrdem) {
  function sortAlfabetico(users) {
    return users.sort((a, b) => {
      if (a.getNickname() < b.getNickname()) {
        return -1
      }
      if (a.getNickname() > b.getNickname()) {
        return 1
      }
      return 0
    })
  }

  function sortDataHora(users) {
    return users.sort((a, b) => {
      if (a.dataHora < b.dataHora) {
        return -1
      }
      if (a.dataHora > b.dataHora) {
        return 1
      }
      return 0
    })
  }

  function sortDataHoraHaiseHand(users) {
    return users.sort((a, b) => {
      if (a.haiseHandDataHora < b.haiseHandDataHora) {
        return -1
      }
      if (a.haiseHandDataHora > b.haiseHandDataHora) {
        return 1
      }
      return 0
    })
  }

  function usersQuestaoOrdemToUsersGeral(usersFiltrado) {
    return usersFiltrado.map((f) =>
      usersGeral.find((g) => g.getStreamId() === f.streamId)
    )
  }

  const questaoOrdem = usersQuestaoOrdemToUsersGeral(
    sortDataHora(usersQuestaoOrdem.filter((u) => u.raised === true))
  )
  const levantouMao = sortDataHoraHaiseHand(
    usersGeral.filter((g) => g.haiseHand === true && !questaoOrdem.includes(g))
  )

  const apresentadoresEModeradores = sortAlfabetico(
    usersGeral.filter(
      (g) =>
        g.isPublishing() &&
        !questaoOrdem.includes(g) &&
        !levantouMao.includes(g)
    )
  )

  const espectadores = sortAlfabetico(
    usersGeral.filter(
      (g) =>
        !g.isPublishing() &&
        !questaoOrdem.includes(g) &&
        !levantouMao.includes(g)
    )
  )
  return [
    {
      grupo: 'questaoOrdemELevantouMao',
      itens: questaoOrdem.concat(levantouMao)
    },
    { grupo: 'apresentadores', itens: apresentadoresEModeradores },
    { grupo: 'espectadores', itens: espectadores }
  ]
}
