/* global location */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import DetectRTC from 'detectrtc';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import deny from 'deny.gif';

function Permission({ onPermissionChanged, onPermissionDeny, denyRender }) {
  const [hasPermission, setHasPermission] = useState(null);
  const [permissionDeny, setPermissionDeny] = useState(false);
  const stream = useRef(null);
  const lastPermission = useRef(null);

  const checkPermission = () => {
    DetectRTC.load(() => {
      setHasPermission(
        DetectRTC.isWebsiteHasMicrophonePermissions &&
          DetectRTC.isWebsiteHasWebcamPermissions
      );
    });
  };

  const refreshPage = () => {
    location.reload();
  };

  useEffect(() => {
    const timer = setInterval(checkPermission, 1000);
    checkPermission();
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (hasPermission === lastPermission.current) return;
    lastPermission.current = hasPermission;
    if (onPermissionChanged) {
      onPermissionChanged(hasPermission);
    }
  }, [hasPermission, onPermissionChanged]);

  const cleanUp = () => {
    if (stream.current !== null) {
      stream.current.getTracks().forEach((track) => track.stop());
    }
  };

  useEffect(() => {
    return cleanUp;
  }, []);

  const promptPermission = () => {
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: true
      })
      .then(function (streamI) {
        stream.current = streamI;
        cleanUp();
      })
      .catch(function () {
        setPermissionDeny(true);
        if (onPermissionDeny) {
          onPermissionDeny();
        }
      })
      .finally(function () {
        checkPermission();
      });
  };

  if (hasPermission || hasPermission === null) {
    return null;
  } else {
    if (permissionDeny) {
      return (
        <Card className='text-center' bg='danger' text='light'>
          <Card.Body>
            <Card.Title>Cam e Mic estão bloqueados</Card.Title>
            {denyRender || (
              <>
                <Card.Img src={deny} />
                <Card.Text>
                  Clique no cadeado na parte superior e autorize a câmera e o
                  microfone
                </Card.Text>
              </>
            )}
            <Button block variant='primary' onClick={refreshPage}>
              Já autorizei o acesso
            </Button>
          </Card.Body>
        </Card>
      );
    } else {
      return (
        <Card className='text-center' bg='light'>
          <Card.Body>
            <Card.Title>
              Para que outros o vejam e o ouçam, seu navegador solicitará acesso
              à sua câmera e microfone
            </Card.Title>
            <Card.Text>Você pode desligá-los a qualquer momento.</Card.Text>
            <Button block variant='primary' onClick={promptPermission}>
              Solicitar Permissão
            </Button>
          </Card.Body>
        </Card>
      );
    }
  }
}

Permission.propTypes = {
  onPermissionChanged: PropTypes.func,
  onPermissionDeny: PropTypes.func,
  denyRender: PropTypes.node
};

export default Permission;
