const resetItens = (pergunta, itens) => {
  const perguntaVoto = {
    perguntaId: pergunta.id,
    voto: []
  }
  itens.forEach((item) => {
    perguntaVoto.voto.push({ seq: item.seq, valor: 0 })
  })
  return perguntaVoto
}

const getVoto = (pergunta, votos) => {
  const votoFind = votos.find((voto) => voto.perguntaId === pergunta.id)
  if (votoFind === undefined) {
    return {}
  }
  return votoFind
}

const montaPerguntaVoto = (
  pergunta,
  itens,
  itemClicado,
  votos,
  perguntaJaVotada
) => {
  const voto = { seq: itemClicado.seq, valor: 1 }
  let perguntaVoto = {}
  if (Object.keys(perguntaJaVotada).length === 0) {
    perguntaVoto = resetItens(pergunta, itens)
  } else {
    perguntaVoto = perguntaJaVotada
    const itemAtual = perguntaVoto.voto.find(
      (item) => item.seq === itemClicado.seq
    )
    if (itemAtual.valor === 1) {
      voto.valor = 0
    } else if (pergunta.nMaxRespostas === 1) {
      perguntaVoto = resetItens(pergunta, itens)
    }
  }
  perguntaVoto.voto.splice(
    perguntaVoto.voto.indexOf(
      perguntaVoto.voto.find((item) => item.seq === itemClicado.seq)
    ),
    1
  )
  perguntaVoto.voto.push(voto)

  return perguntaVoto
}

const temPeloMenosUmSim = (perguntaVoto) => {
  let nSim = 0
  perguntaVoto.voto.forEach((el) => {
    nSim += el.valor
  })
  return nSim >= 1
}

const votar = (pergunta, itens, itemClicado, votos) => {
  const votosInterno = [...votos]
  const perguntaJaVotada = getVoto(pergunta, votosInterno)

  if (Object.keys(perguntaJaVotada).length !== 0) {
    votosInterno.splice(votosInterno.indexOf(perguntaJaVotada), 1)
  }
  const perguntaVoto = montaPerguntaVoto(
    pergunta,
    itens,
    itemClicado,
    votosInterno,
    perguntaJaVotada
  )

  if (temPeloMenosUmSim(perguntaVoto)) {
    votosInterno.push(perguntaVoto)
  }
  return votosInterno
}

export default votar
