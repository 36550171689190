import React, { useState } from 'react'
import Container from 'react-bootstrap/Container'
import { Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faCalendar } from '@fortawesome/free-regular-svg-icons'
import Button from 'react-bootstrap/Button'
import { faAt, faPrint } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import PropTypes from 'prop-types'
import EnviarComprovanteModal from './components/EnviarComprovanteModal'
import Styles from './Comprovante.module.css'

function Linha({ titulo, children }) {
  return (
    <div className="d-flex">
      <div className="p-2 text-white bg-primary w-25 border border-right-0">
        {titulo}
      </div>
      <div className="p-2 text-muted w-75 border overflow-auto">{children}</div>
    </div>
  )
}

Linha.propTypes = {
  titulo: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}

function LinhaVotoSeparado() {
  return (
    <div className="d-flex info-voto-separado">
      <div className="p-2 text-white text-center bg-primary w-100 border border">
        VOTO EM SEPARADO
      </div>
    </div>
  )
}

function InformacoesVotoSeparado() {
  return (
    <div className="text-danger mx-auto comprovante mt-2 info-voto-separado">
      <small>
        Você votou em separado. De posse de seus dados, a entidade organizadora
        irá verificar se, de acordo com o estatuto da entidade e as regras do
        evento, está apto a votar. Nesse caso, seu voto será computado
        normalmente.
      </small>
    </div>
  )
}

function InformacoesPrimeiraVia() {
  return (
    <div className="text-primary mx-auto comprovante mt-2">
      <small>
        Não esqueça de imprimir ou enviar por e-mail o seu comprovante de voto.
        Por uma questão de <b>segurança</b> o código HASH não estará disponível
        em caso de reemissão.
      </small>
    </div>
  )
}

function Comprovante({
  showPrint,
  onActionClick,
  actionLabel,
  dadosComprovante,
  evento,
  urlApiAssembleia,
  userToken,
  userEmail,
  onLogoutClick,
  logoutLabel
}) {
  const [openEnviarEmail, setOpenEnviarEmail] = useState(false)

  const handlePrintClick = () => window.print()
  const handleActionClick = () => onActionClick()
  const handleLogoutClick = () => onLogoutClick()

  const handleEnviarEmailClick = () => setOpenEnviarEmail(true)

  const primeiraVia = dadosComprovante.comprovante !== undefined
  const tamanhoBotao =
    onLogoutClick && logoutLabel && primeiraVia && showPrint ? 3 : 4

  return (
    <Container className={`text-center h-100 ${Styles.displayGrid}`}>
      <Container className="m-auto p-0">
        <h1 className="text-success mb-3">Voto Recebido com Sucesso!</h1>
        <h6 className="d-print-none mb-2">Veja seu comprovante de voto:</h6>
        <div className="d-flex flex-column font-weight-bold fw-bold comprovante text-left text-start mt-4 text-uppercase shadow-sm">
          {dadosComprovante.votoSeparado && <LinhaVotoSeparado />}
          <Linha titulo="EVENTO:">{evento.nome}</Linha>
          <Linha titulo="NOME:">{dadosComprovante.nome}</Linha>
          <Linha titulo="DATA:">
            <div className="d-flex">
              <div className="border-right w-50">
                <FontAwesomeIcon icon={faCalendar} />{' '}
                {moment(dadosComprovante.dataHoraVotacao)
                  .utcOffset(-3)
                  .format('DD/MM/YYYY')}
              </div>
              <div className="w-50 ps-2 pl-2">
                <FontAwesomeIcon icon={faClock} />{' '}
                {moment(dadosComprovante.dataHoraVotacao)
                  .utcOffset(-3)
                  .format('HH:mm:ss')}{' '}
                <small className="text-lowercase">(Horário de Brasília)</small>
              </div>
            </div>
          </Linha>
          <Linha titulo="IP:">{dadosComprovante.ipVotacao}</Linha>
          <Linha titulo="HASH:">
            <small className="text-primary overflow-auto">
              {primeiraVia
                ? dadosComprovante.comprovante
                : 'Disponível apenas na primeira VIa'}
            </small>
          </Linha>
        </div>
        {dadosComprovante.votoSeparado && <InformacoesVotoSeparado />}
        {primeiraVia && <InformacoesPrimeiraVia />}
        <Row className="botoes mt-5 m-auto d-print-none justify-content-center">
          {showPrint && (
            <Col md={tamanhoBotao}>
              <Button
                onClick={handlePrintClick}
                size="lg"
                variant="secondary"
                className="px-4 w-100 mb-2 "
              >
                <FontAwesomeIcon icon={faPrint} /> IMPRIMIR
              </Button>
            </Col>
          )}
          {primeiraVia && (
            <Col md={tamanhoBotao}>
              <Button
                onClick={handleEnviarEmailClick}
                size="lg"
                variant="secondary"
                className="w-100 mb-2"
              >
                <FontAwesomeIcon icon={faAt} /> ENVIAR POR E-MAIL
              </Button>
            </Col>
          )}
          <Col md={tamanhoBotao}>
            <Button
              onClick={handleActionClick}
              size="lg"
              variant="primary"
              className="w-100 px-4 mb-2 "
            >
              {actionLabel}
            </Button>
          </Col>
          {logoutLabel && onLogoutClick && (
            <Col md={tamanhoBotao}>
              <Button
                onClick={handleLogoutClick}
                size="lg"
                variant="primary"
                className="w-100 px-4 mb-2 "
              >
                {logoutLabel}
              </Button>
            </Col>
          )}
        </Row>
        <EnviarComprovanteModal
          urlApiAssembleia={urlApiAssembleia}
          userToken={userToken}
          comprovanteHash={dadosComprovante.comprovante}
          emailDefault={userEmail}
          eventoId={evento.id}
          onFechar={() => {
            setOpenEnviarEmail(false)
          }}
          open={openEnviarEmail}
        />
      </Container>
    </Container>
  )
}

Comprovante.propTypes = {
  showPrint: PropTypes.bool,
  onActionClick: PropTypes.func.isRequired,
  actionLabel: PropTypes.string.isRequired,
  evento: PropTypes.shape({
    id: PropTypes.number.isRequired,
    nome: PropTypes.string.isRequired
  }).isRequired,
  dadosComprovante: PropTypes.shape({
    comprovante: PropTypes.string,
    nome: PropTypes.string.isRequired,
    votoSeparado: PropTypes.bool.isRequired,
    dataHoraVotacao: PropTypes.string.isRequired,
    ipVotacao: PropTypes.string.isRequired
  }).isRequired,
  urlApiAssembleia: PropTypes.string.isRequired,
  userToken: PropTypes.string.isRequired,
  userEmail: PropTypes.string,
  onLogoutClick: PropTypes.func,
  logoutLabel: PropTypes.string
}

Comprovante.defaultProps = {
  showPrint: false,
  userEmail: '',
  onLogoutClick: null,
  logoutLabel: null
}

export default Comprovante
