import React from 'react'
import PropTypes from 'prop-types'

import DraggableDialog from '../../DraggableModal/DraggableModal'

import {
  ThumbDownRounded,
  ThumbUpRounded,
  ThumbsUpDownRounded
} from '@material-ui/icons'
import { IconButton, Typography } from '@material-ui/core'

import './ModalVote.scss'

const ModalVote = ({ onClose, onVote, isOpen }) => {
  return (
    <DraggableDialog title='Qual o seu voto?' isOpen={isOpen} onClose={onClose}>
      <div className='modalVoteContent'>
        <div className='voteButtonWrapper voteYes'>
          <IconButton className='voteButton' onClick={() => onVote('Sim')}>
            <ThumbUpRounded className='iconButton' />
          </IconButton>
          <Typography variant='h5'>Sim</Typography>
        </div>
        <div className='voteButtonWrapper voteBlank'>
          <IconButton
            className='voteButton'
            onClick={() => onVote('Abstenção')}
          >
            <ThumbsUpDownRounded className='iconButton' />
          </IconButton>
          <Typography variant='h5'>Abstenção</Typography>
        </div>
        <div className='voteButtonWrapper voteNo'>
          <IconButton className='voteButton' onClick={() => onVote('Não')}>
            <ThumbDownRounded className='iconButton' />
          </IconButton>
          <Typography variant='h5'>Não</Typography>
        </div>
      </div>
    </DraggableDialog>
  )
}

ModalVote.propTypes = {
  onVote: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
}

export default ModalVote
