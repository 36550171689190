import { useEffect } from 'react'

const useEventEmitter = (eventName, eventEmitter, onReceiveData) => {
  useEffect(() => {
    if (!eventEmitter || !eventName) return

    eventEmitter.on(eventName, onReceiveData)

    return () => {
      eventEmitter.off(eventName, onReceiveData)
    }
  }, [eventName, eventEmitter, onReceiveData])
}

export default useEventEmitter
