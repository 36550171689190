import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import Timer from '../Timer/Timer'
import { EVENTS } from '../../shared/constants/constants'

const SEND_MESSAGE_INTERVAL = 10000

const TimerConnector = ({ messageManager, userProfile, otherUsers }) => {
  const [timerStatus, setTimerStatus] = useState(null)

  const sendTimerStatus = (sendData) => {
    setTimerStatus(sendData)
  }

  useEffect(() => {
    if (!messageManager || timerStatus === null) {
      return
    }

    messageManager.sendCustomData(EVENTS.SET_TIMER_STATUS_EVENT, timerStatus)

    const sendVoteInterval = setInterval(() => {
      messageManager.sendCustomData(EVENTS.SET_TIMER_STATUS_EVENT, timerStatus)
    }, SEND_MESSAGE_INTERVAL)

    return () => {
      clearInterval(sendVoteInterval)
    }
  }, [timerStatus, messageManager])

  return (
    <Timer
      eventEmitter={messageManager}
      eventName={EVENTS.DATA_RECEIVE_EVENT}
      userId={messageManager ? messageManager.streamId : null}
      onTimerStatusChange={sendTimerStatus}
      isAllowedToInitTimer={userProfile === 'moderador'}
    />
  )
}

TimerConnector.propTypes = {
  otherUsers: PropTypes.array.isRequired,
  userProfile: PropTypes.string.isRequired,
  messageManager: PropTypes.any
}

export default TimerConnector
