import ACTIONS from './actions'

export default (state, action) => {
  switch (action.type) {
    case ACTIONS.OPEN_POLL:
      if (state.open === true) {
        return state
      }
      return { ...state, open: true, pollVotesList: new Map([]) }
    case ACTIONS.CLEAN_RESULTS:
      if (state.results.size === 0 && state.totalUsers === 0) {
        return state
      }
      return { ...state, results: new Map([]), totalUsers: 0 }
    case ACTIONS.UPDATE_RESULTS:
      if (
        action.results &&
        state.results.size === action.results.size &&
        state.totalUsers === action.totalUsers
      ) {
        return state
      }
      if (
        !action.results &&
        state.results.size === state.pollVotesList.size &&
        state.totalUsers === action.totalUsers
      ) {
        return state
      }
      return {
        ...state,
        totalUsers: action.totalUsers,
        results: action.results ? action.results : new Map(state.pollVotesList)
      }
    case ACTIONS.CLOSE_POLL:
      if (state.open === false) {
        return state
      }
      return { ...state, open: false }
    case ACTIONS.REGISTER_VOTE:
      if (
        !state.open ||
        state.pollVotesList.get(action.id) ||
        state.pollVotesList.get(action.id) === action.vote
      ) {
        return state
      }
      return {
        ...state,
        pollVotesList: state.pollVotesList.set(action.id, action.vote)
      }
    case ACTIONS.SET_POLL_OWNER:
      return {
        ...state,
        pollOwner: action.value
      }
    default:
      throw new Error()
  }
}
