import React from 'react'
import Draggable from 'react-draggable'

import { Paper as MaterialPaper, withWidth } from '@material-ui/core'

const Paper = (props) => {
  if (props.width === 'xs') {
    return <MaterialPaper {...props} />
  }

  return (
    <Draggable
      handle='#dialog-title'
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <MaterialPaper {...props} />
    </Draggable>
  )
}

export default withWidth()(Paper)
