import ACTIONS from './actions'

export default (state, action) => {
  switch (action.type) {
    case ACTIONS.INIT_TIMER:
      return {
        ...state,
        timerStatus: true,
        timestamp: action.timestamp,
        time: action.time,
        stoppedBeforeEnd: false
      }
    case ACTIONS.STOP_TIMER:
      return {
        ...state,
        timerStatus: false,
        timestamp: null,
        time: null
      }
    case ACTIONS.SET_TIMER_OWNER:
      return {
        ...state,
        timerOwner: action.value
      }
    default:
      throw new Error()
  }
}
