import React, { useState } from 'react';
import CamTest from './CamTest';
import MicTest from './MicTest';
import styles from './styles.module.css';
import PropTypes from 'prop-types';
import Permission from './Permission';

function CamMicTest({ className, denyRender }) {
  const [hasPermission, setHasPermission] = useState(null);

  const permissionChanged = (hasPermission) => {
    setHasPermission(hasPermission);
  };

  return (
    <div className={className}>
      {hasPermission ? (
        <React.Fragment>
          <div className={styles.camTest}>
            <CamTest />
          </div>
          <div className={styles.micTest}>
            <MicTest />
          </div>
        </React.Fragment>
      ) : (
        <Permission
          onPermissionChanged={permissionChanged}
          denyRender={denyRender}
        />
      )}
    </div>
  );
}

CamMicTest.propTypes = {
  className: PropTypes.string,
  denyRender: PropTypes.node
};

export default CamMicTest;

export { Permission, CamTest, MicTest };
