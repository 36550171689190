import React, { useEffect, useState } from 'react'
import parse from 'html-react-parser'
import Button from 'react-bootstrap/Button'
import { Alert, Container, Row } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import PropTypes from 'prop-types'
import Loading from '../loading/Loading'
import Styles from './Termo.module.css'

function Termo({ urlApiAssembleia, eventoId, userToken, onConcordou }) {
  const [termoHtml, setTermoHtml] = useState(null)
  const [loading, setLoading] = useState(true)
  const [concordouCheck, setConcordouCheck] = useState(false)
  const [checkboxErro, setCheckboxErro] = useState(false)

  const concordarHandle = async () => {
    if (!concordouCheck) {
      setCheckboxErro(true)
      return
    }
    setLoading(true)
    const termoReturn = await fetch(
      `${urlApiAssembleia}/eventos/${eventoId}/termo/concordar`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${userToken}`
        }
      }
    )

    setLoading(false)
    if (termoReturn.status === 201) {
      onConcordou()
      return
    }
    const resposta = await termoReturn.json()
    console.error(resposta)
    if (resposta.mensagem) {
      alert(resposta.mensagem)
    } else {
      alert('Ops.. Erro ao concordar com termo, tente novamente mais tarde')
    }
  }

  useEffect(() => {
    let abortController = null
    if (window.AbortController) {
      abortController = new AbortController()
    }
    const fetchTermo = async () => {
      try {
        setLoading(true)
        const termoReturn = await fetch(
          `${urlApiAssembleia}/eventos/${eventoId}/termo`,
          {
            method: 'GET',
            signal: abortController?.signal ?? null,
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${userToken}`
            }
          }
        )
        setLoading(false)
        if (termoReturn.status === 200) {
          const termo = await termoReturn.json()
          setTermoHtml(termo.termoHtml)
        } else {
          const resposta = await termoReturn.json()
          console.error(resposta)
          if (resposta.mensagem) {
            alert(resposta.mensagem)
          } else {
            alert('Ops.. Erro ao recuperar termo, tente novamente mais tarde')
          }
        }
      } catch (e) {
        if (e.name === 'AbortError') {
          console.log(e)
        } else {
          console.error(e)
        }
      }
    }

    fetchTermo()
    return () => {
      if (abortController) {
        abortController.abort()
      }
    }
  }, [eventoId, urlApiAssembleia, userToken])

  if (loading) {
    return <Loading />
  }

  return (
    <Container className={`h-100 ${Styles.styledContainer}`}>
      <h1 className="text-center">Termos e condições</h1>
      {loading || !termoHtml ? (
        <Loading />
      ) : (
        <div id="termo-container-body" className="d-flex flex-column h-100">
          <div className={`overflow-auto h-100 ${Styles.styledTextContainer}`}>
            <div className="my-auto">{parse(termoHtml)}</div>
          </div>

          <div className="text-left mt-4 mb-3">
            <Row>
              <Form.Check
                className={
                  Styles.styledCheckbox + (checkboxErro ? ' text-danger' : '')
                }
                inline
                type="checkbox"
                id="check-concordo"
                checked={concordouCheck}
                onChange={() => {
                  setConcordouCheck(!concordouCheck)
                }}
                label={
                  <span className="fw-bold font-weight-bold">
                    Declaro que li e aceito os termos & condições
                  </span>
                }
              />
            </Row>
            <Row className={Styles.floatRight}>
              <Button size="lg" variant="success" onClick={concordarHandle}>
                Continuar
              </Button>
            </Row>
          </div>

          {checkboxErro && (
            <Alert variant="danger" data-testid="alert-erro">
              Clique em concordar com o termo para continuar
            </Alert>
          )}
        </div>
      )}
    </Container>
  )
}
Termo.propTypes = {
  urlApiAssembleia: PropTypes.string.isRequired,
  eventoId: PropTypes.number.isRequired,
  userToken: PropTypes.string.isRequired,
  onConcordou: PropTypes.func.isRequired
}

export default Termo
