/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

import PropTypes from 'prop-types'
import Loading from '../loading/Loading'
import PerguntaItens from '../pergunta-itens/PerguntaItens'
import Styles from './ConfirmarVoto.module.css'

function ConfirmarVoto({
  votos,
  onVoltarClick,
  onConfirmou,
  urlApiAssembleia,
  eventoId,
  userToken
}) {
  const [loading, setLoading] = useState(true)
  const [votando, setVotando] = useState(false)
  const [perguntas, setPerguntas] = useState([])

  useEffect(() => {
    const fetchPerguntas = async () => {
      setLoading(true)

      const perguntaReturn = await fetch(
        `${urlApiAssembleia}/eventos/${eventoId}/perguntas`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${userToken}`
          }
        }
      )

      setLoading(false)
      if (perguntaReturn.status === 200) {
        const perguntaJson = await perguntaReturn.json()
        setPerguntas(perguntaJson)
        return
      }
      const resposta = await perguntaReturn.json()
      console.error(resposta)
      if (resposta.mensagem) {
        alert(resposta.mensagem)
      } else {
        alert('Ops.. Erro ao carregar perguntas, tente novamente mais tarde')
      }
    }
    fetchPerguntas()
  }, [eventoId, urlApiAssembleia, userToken])

  const enviarVotos = async () => {
    setVotando(true)
    const votarReturn = await fetch(
      `${urlApiAssembleia}/eventos/${eventoId}/votar`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`
        },
        body: JSON.stringify({ votos })
      }
    )

    setVotando(false)
    switch (votarReturn.status) {
      case 404:
      case 400:
      case 422:
        alert((await votarReturn.json()).mensagem)
        break
      case 401:
        console.debug(await votarReturn.text())
        break
      case 201:
        onConfirmou(await votarReturn.json())
        break
      default:
        alert('Erro ao votar')
        console.error(await votarReturn.text())
        break
    }
  }

  if (loading) {
    return <Loading />
  }

  return (
    <Container className="text-center overflow-auto">
      <h1 className={`${Styles.cursorDefault}`}>Confirme seu voto</h1>
      <Row id="confirma-voto-container-body" className="justify-content-center">
        {perguntas.map((pergunta) => (
          <Col className="py-2" xs={12} lg={6} key={pergunta.id}>
            <Card className="shadow-sm">
              <Card.Body className={`pt-1 ${Styles.styledQuestion}`}>
                <PerguntaItens
                  sohChecked
                  readOnly
                  titulo={pergunta.titulo}
                  pergunta={pergunta}
                  votos={votos}
                  urlApiAssembleia={urlApiAssembleia}
                  eventoId={eventoId}
                  userToken={userToken}
                />
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      <div className="mt-3 d-flex justify-content-between">
        <Button
          onClick={onVoltarClick}
          disabled={votando}
          size="lg"
          variant="secondary"
        >
          VOLTAR
        </Button>
        <Button
          onClick={enviarVotos}
          disabled={votando}
          size="lg"
          variant="primary"
        >
          CONFIRMAR
        </Button>
      </div>
    </Container>
  )
}

ConfirmarVoto.propTypes = {
  votos: PropTypes.arrayOf(
    PropTypes.shape({
      perguntaId: PropTypes.number.isRequired,
      voto: PropTypes.arrayOf(
        PropTypes.shape({
          seq: PropTypes.number.isRequired,
          valor: PropTypes.oneOf([0, 1]).isRequired
        })
      )
    })
  ).isRequired,
  onVoltarClick: PropTypes.func.isRequired,
  onConfirmou: PropTypes.func.isRequired,
  urlApiAssembleia: PropTypes.string.isRequired,
  eventoId: PropTypes.number.isRequired,
  userToken: PropTypes.string.isRequired
}

export default ConfirmarVoto
