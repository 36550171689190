/* global fetch */

import WebRTCAdaptor from './webrtc-adaptor';

class WebrtcPublisher {
  constructor({
    urlApi,
    url,
    roomId,
    tokenApi,
    streamId,
    messageManager,
    screenShareStoppedCallback,
    publishStartedCallback,
    showErrorMsgCallback
  }) {
    this.url = url;
    this.urlApi = urlApi;
    this.tokenApi = tokenApi;
    this.roomId = roomId;
    this.tokenPublish = null;
    this.streamId = streamId;
    this.webRTCPublisher = null;
    this.timerRepublish = null;
    this.republishing = false;
    this.initing = false;

    this.screenShareStoppedCallback = screenShareStoppedCallback;
    this.publishStartedCallback = publishStartedCallback;
    this.showErrorMsgCallback = showErrorMsgCallback;
    this.messageManager = messageManager;
  }

  init = async () => {
    if (this.initing) return;
    this.initing = true;
    console.info('iniciando publish');
    try {
      while (document.getElementById('localVideo') === null) {
        console.debug('#localVideo não criado, aguardando...');
        await new Promise((resolve) => setTimeout(resolve, 500));
      }

      this.webRTCPublisher = new WebRTCAdaptor(this.url, false, {
        initializedCallback: this.initializePublisher,
        dataReceivedCallback: this.messageManager.onDataReceive,
        publishStartedCallback: this.publishStarted,
        errorGenericCallback: this.errorPublisher,
        errorScreenSharePermissionDeniedCallback:
          this.screenShareStoppedCallback,
        screenShareStoppedCallback: this.screenShareStoppedCallback
      });
    } catch (e) {
      console.warn(e);
      this.initing = false;
    }
  };

  refreshTokenPublish = async () => {
    const fet = await fetch(this.urlApi + '/token-publish', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.tokenApi
      }
    });

    if (fet.status !== 200) {
      console.error(await fet.text());
      return;
    }
    this.tokenPublish = (await fet.json()).token;
  };

  initializePublisher = async () => {
    this.initing = false;
    try {
      await this.refreshTokenPublish();
      this.webRTCPublisher.publish(this.streamId, this.tokenPublish);
    } catch (e) {
      console.warn(e);
    }
  };

  publishStarted = () => {
    this.createTimerRepublish();
    this.publishStartedCallback();
  };

  createTimerRepublish = () => {
    if (this.timerRepublish === null) {
      this.timerRepublish = setInterval(() => {
        this.checkAndRepublishIfRequired();
      }, 3000);
    }
  };

  errorPublisher = async (error, msg) => {
    this.initing = false;
    console.warn('errorPublisher');
    console.log(error, msg);
    this.showErrorMsgCallback(error);
    this.createTimerRepublish();
  };

  checkAndRepublishIfRequired = async () => {
    if (this.republishing) return;
    if (this.initing) return;
    this.republishing = true;
    try {
      this.webRTCPublisher.joinRoom(this.roomId, this.streamId);
      var iceState = this.webRTCPublisher.iceConnectionState(this.streamId);
    } catch (e) {
      console.info(e);
      iceState = null;
    }
    console.info('Ice state checked = ' + iceState);
    if (
      iceState === null ||
      iceState === 'failed' ||
      iceState === 'disconnected'
    ) {
      try {
        this.webRTCPublisher.kill(this.streamId, false);
        await this.init();
      } catch (e) {
        console.warn(e);
      }
    }
    this.republishing = false;
  };

  kill = () => {
    clearInterval(this.timerRepublish);
    this.webRTCPublisher.kill(this.streamId, true);
  };

  muteLocalMic = () => {
    this.webRTCPublisher.muteLocalMic();
  };

  turnOffLocalCamera = () => {
    this.webRTCPublisher.turnOffLocalCamera();
  };

  unmuteLocalMic = () => {
    this.webRTCPublisher.unmuteLocalMic();
  };

  turnOnLocalCamera = () => {
    this.webRTCPublisher.turnOnLocalCamera();
  };

  switchDesktopCaptureWithCamera = (streamId) => {
    this.webRTCPublisher.switchDesktopCaptureWithCamera(streamId);
  };

  switchDesktopCapture = (streamId) => {
    this.webRTCPublisher.switchDesktopCapture(streamId);
  };

  switchVideoCameraCapture = (streamId) => {
    this.webRTCPublisher.switchVideoCameraCapture(streamId);
  };

  sendData = (streamId, message) => {
    this.webRTCPublisher.sendData(streamId, message);
  };

  getPublishMode = () => {
    return this.webRTCPublisher.publishMode;
  };
}

export default WebrtcPublisher;
