import React from 'react'
import PropTypes from 'prop-types'

import {
  IconButton,
  Typography,
  DialogTitle as MuiDialogTitle
} from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'

const DialogTitle = ({ children, onClose }) => {
  return (
    <MuiDialogTitle id='dialog-title' disableTypography>
      <Typography className='title' variant='h6'>
        {children}
      </Typography>
      {onClose && (
        <IconButton className='closeModal' onClick={onClose}>
          <CloseIcon />
        </IconButton>
      )}
    </MuiDialogTitle>
  )
}

DialogTitle.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func
}

export default DialogTitle
