import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import Poll from '../Poll/Poll'

const SEND_MESSAGE_INTERVAL = 1000

const PollConnector = ({ messageManager, userProfile, otherUsers }) => {
  const [voteInfo, setVoteInfo] = useState(null)
  const [pollStatus, setPollStatus] = useState(null)

  const sendVote = (sendData) => {
    setVoteInfo(sendData)
  }

  const sendPollStatus = (sendData) => {
    setPollStatus({
      ...sendData,
      results: JSON.stringify(Array.from(sendData.results.entries()))
    })
  }

  useEffect(() => {
    if (!messageManager || !voteInfo) {
      return
    }

    const sendVoteInterval = setInterval(() => {
      messageManager.sendCustomData('TYPE-VOTE', voteInfo)
    }, SEND_MESSAGE_INTERVAL)

    return () => {
      clearInterval(sendVoteInterval)
    }
  }, [pollStatus, voteInfo, messageManager])

  useEffect(() => {
    if (!messageManager || pollStatus === null) {
      return
    }

    const sendPollStatusInterval = setInterval(() => {
      messageManager.sendCustomData('TYPE-OPEN-POLL', pollStatus)
    }, SEND_MESSAGE_INTERVAL)

    return () => {
      clearInterval(sendPollStatusInterval)
    }
  }, [pollStatus, messageManager])

  return (
    <Poll
      eventEmitter={messageManager}
      eventName='data-receive'
      userId={messageManager ? messageManager.streamId : null}
      onVote={sendVote}
      totalUsers={otherUsers.length + 1}
      onPollStatusChange={sendPollStatus}
      isAllowedToOpenPoll={userProfile === 'moderador'}
    />
  )
}

PollConnector.propTypes = {
  otherUsers: PropTypes.array.isRequired,
  userProfile: PropTypes.string.isRequired,
  messageManager: PropTypes.any
}

export default PollConnector
