import React from 'react'
import PropTypes from 'prop-types'
import {
  Dialog as MaterialDialog,
  Paper,
  DialogContent
} from '@material-ui/core'

import DialogTitle from './Title/Title'
import './Dialog.scss'

const Dialog = ({ children, title, isOpen, onClose, testId }) => {
  return (
    <MaterialDialog
      data-testid={testId}
      className='dialog'
      open={isOpen}
      onClose={onClose}
      PaperComponent={Paper}
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
    >
      {title && <DialogTitle onClose={onClose}>{title}</DialogTitle>}
      <DialogContent>{children}</DialogContent>
    </MaterialDialog>
  )
}

Dialog.propTypes = {
  children: PropTypes.node.isRequired,
  testId: PropTypes.string,
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
}

export default Dialog
